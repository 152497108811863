import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { CloseRounded as CloseIcon } from '@mui/icons-material'

import {
    Alert,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Typography,
} from '@mui/material'
import SearchTermComponent from '../../components/searchTermComponent/SearchTermComponent'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../app/hooks'
import { selectListEvents } from '../eventsToolbar/store/eventsSlice'
import {
    createNewResponsePlan,
    selectOpenResponsePlanCreateDialog,
    selectResponsePlanCreated,
    selectResponsePlanCreating,
    setOpenResponsePlanCreateDialog,
    setResponsePlanCreated,
    setResponsePlanSelected,
} from '../responsePlanToolbar/store/responsePlanSlice'
import {
    ResponsePlanDialogButton,
    ResponsePlanDialogButtonsWrapper,
    ResponsePlanDialogContentStyled,
    ResponsePlanDialogEventsActionsWrapper,
    ResponsePlanDialogIconTitleWrapper,
    ResponsePlanDialogListWrapper,
    ResponsePlanDialogName,
    ResponsePlanDialogResetButton,
    ResponsePlanDialogSeparator,
    ResponsePlanDialogSnackbarStyled,
    ResponsePlanDialogTitle,
    ResponsePlanDialogTitleInfoWrapper,
    ResponsePlanDialogTitleStyled,
    ResponsePlanDialogTitleWrapper,
    ResponsePlanSearchTermWrapper,
    ResponsePlanSelectActionsEventsWrapper,
} from './responsePlanCreateDialogStyled'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { selectListActions } from '../actionsToolbar/store/actionsSlice'
import { getNames, selectAvailableNames } from '../core/coreSlice'

const typeToFind = {
    event: 'event',
    action: 'action',
}

export const ResponsePlanCreateDialog: React.FC = () => {
    const { t } = useTranslation()
    const _openResponsePlanCreateDialog: boolean = useAppSelector(selectOpenResponsePlanCreateDialog)
    const _listEvents: any[] = useAppSelector(selectListEvents)
    const _listActions: any[] = useAppSelector(selectListActions)
    const _responsePlanCreating: boolean = useAppSelector(selectResponsePlanCreating)
    const _responsePlanMade: boolean = useAppSelector(selectResponsePlanCreated)
    const _availableNames = useAppSelector(selectAvailableNames)

    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<{
        message: string
        type?: 'success' | 'info' | 'warning' | 'error'
        duration: number
    }>({
        message: '',
        duration: 0,
    })
    const [responsePlanName, setResponsePlanName] = useState<string>('')
    const [searchEventTerm, setSearchEventTerm] = useState<string>('')
    const [searchActionTerm, setSearchActionTerm] = useState<string>('')
    const [showSearchEventReset, setSearchEventReset] = useState<boolean>(false)
    const [showSearchActionReset, setSearchActionReset] = useState<boolean>(false)
    const [eventsFiltered, setEventsFiltered] = useState<any[]>([])
    const [actionsFiltered, setActionsFiltered] = useState<any[]>([])
    const [postingData, setPostingData] = useState<boolean>(false)
    const [selectedEvents, setSelectedEvents] = useState<number[]>([])
    const [selectedActions, setSelectedActions] = useState<number[]>([])
    const [disabled, setDisabled] = useState<boolean>(false)

    const invalidName: boolean = useMemo(
        () => _availableNames.response.map(name => name.toLowerCase()).includes(responsePlanName.toLowerCase()),
        [_availableNames, responsePlanName]
    )

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNames('response'))
    }, [dispatch])

    useEffect(() => {
        setEventsFiltered(_listEvents)
    }, [_listEvents])

    useEffect(() => {
        setActionsFiltered(_listActions)
    }, [_listActions])

    useEffect(() => {
        if (!_responsePlanCreating && _responsePlanMade) {
            setPostingData(false)
            showEventFeedback(t('manageResponsePLan.responsePlanCreated'), 'success')
            dispatch(setResponsePlanCreated(false))

            setTimeout(() => {
                setShowFeedback(false)
                setFeedback({
                    message: 'message',
                    duration: 0,
                })
                handleClose()
                setDisabled(false)
            }, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_responsePlanCreating, _responsePlanMade])

    const handleClose = () => {
        dispatch(setOpenResponsePlanCreateDialog(false))
        dispatch(setResponsePlanSelected(false))
        setShowFeedback(false)
        setPostingData(false)
    }

    const handleFeedbackClose = () => {
        setShowFeedback(false)
    }

    const handleCheckedEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _checkedId = Number.parseInt(event.target.value)
        let selected = selectedEvents.slice()

        selected.includes(_checkedId)
            ? (selected = selected.filter(id => _checkedId !== id))
            : selected.push(_checkedId)

        setSelectedEvents(selected)
    }

    const handleCheckedAction = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _checkedId = Number.parseInt(event.target.value)
        let selected = selectedActions.slice()

        selected.includes(_checkedId)
            ? (selected = selected.filter(id => _checkedId !== id))
            : selected.push(_checkedId)

        setSelectedActions(selected)
    }

    const searchActionTermChange = (searchTerm: string) => {
        setSearchActionTerm(searchTerm)
        if (searchTerm !== '' && searchTerm.length > 2) {
            const searchActionResult = search(searchTerm, typeToFind.action)
            setActionsFiltered(searchActionResult)
            setSearchActionReset(true)
        } else {
            setSearchActionReset(false)
            setActionsFiltered(_listActions)
        }
    }

    const searchEventTermChange = (searchTerm: string) => {
        setSearchEventTerm(searchTerm)
        if (searchTerm !== '' && searchTerm.length > 2) {
            const searchEventResult = search(searchTerm, typeToFind.event)
            setEventsFiltered(searchEventResult)
            setSearchEventReset(true)
        } else {
            setSearchEventReset(false)
            setEventsFiltered(_listEvents)
        }
    }

    const search = (value: string, type: string) => {
        const allToFind = type === typeToFind.action ? _listActions : _listEvents
        const searchResult = allToFind.filter((action: any) => action.name.toLowerCase().includes(value.toLowerCase()))
        if (searchResult.length === 0) {
            showEventFeedback(`${t(`manageResponsePLan.${type}`)} ${t('manageResponsePLan.notFound')}`, 'warning')
        }
        return searchResult
    }

    const showEventFeedback = (message: string, type: 'success' | 'info' | 'warning' | 'error' = 'info') => {
        setShowFeedback(true)
        setFeedback({
            message: message,
            type: type,
            duration: 3000,
        })
        setTimeout(() => {
            setShowFeedback(false)
        }, 3000)
    }
    const resetEventsClick = () => {
        setSearchEventTerm('')
        setSearchEventReset(false)
        setEventsFiltered(_listEvents)
    }

    const resetActionsClick = () => {
        setSearchActionTerm('')
        setSearchActionReset(false)
        setActionsFiltered(_listActions)
    }

    const newResponsePlanHandler = () => {
        setPostingData(true)
        setDisabled(true)
        const responsePlan = {
            name: responsePlanName,
            events: selectedEvents,
            actions: selectedActions,
        }
        dispatch(createNewResponsePlan({ responsePlan }))
    }

    const enableRunButton = responsePlanName !== '' && selectedActions.length > 0 && selectedEvents.length > 0
    const errorRpName = responsePlanName === ''

    const helperText: string = useMemo(() => {
        if (errorRpName) {
            return 'This field is required'
        } else if (invalidName) {
            return 'Name already exists'
        }
        return ''
    }, [errorRpName, invalidName])

    return (
        <Dialog
            onClose={() => {}}
            fullWidth
            maxWidth='xl'
            aria-labelledby='charts-dialog-title'
            open={_openResponsePlanCreateDialog}
            disableEscapeKeyDown={true}>
            <ResponsePlanDialogTitleStyled id='response-plan-manage-dialog-title'>
                <ResponsePlanDialogTitleInfoWrapper>
                    <ResponsePlanDialogTitleWrapper>
                        <ResponsePlanDialogIconTitleWrapper>
                            <CustomIcon name='responsePlan' style={{ marginTop: '0' }} />
                            <ResponsePlanDialogTitle variant='h2'>
                                {t('manageResponsePLan.newResponsePlan')}
                            </ResponsePlanDialogTitle>
                        </ResponsePlanDialogIconTitleWrapper>
                        <IconButton aria-label='delete' onClick={handleClose} style={{ width: '30px', height: '30px' }}>
                            <CloseIcon />
                        </IconButton>
                    </ResponsePlanDialogTitleWrapper>
                </ResponsePlanDialogTitleInfoWrapper>
                <ResponsePlanDialogSeparator />
            </ResponsePlanDialogTitleStyled>
            <ResponsePlanDialogContentStyled id='response-dialog-content'>
                <ResponsePlanDialogName
                    id='responsePlanName'
                    value={responsePlanName}
                    placeholder={t('manageResponsePLan.insertNameResponsePlan')}
                    label={t('manageResponsePLan.responsePlanName')}
                    variant={'standard'}
                    autoFocus={true}
                    onChange={e => setResponsePlanName(e.target.value)}
                    style={{
                        width: '100%',
                        marginBottom: '20px',
                    }}
                    error={errorRpName || invalidName}
                    helperText={helperText}
                />
                <ResponsePlanDialogEventsActionsWrapper>
                    <ResponsePlanSelectActionsEventsWrapper>
                        <Typography variant='h2'>{t('manageResponsePLan.events')}</Typography>
                        <ResponsePlanSearchTermWrapper>
                            <SearchTermComponent
                                value={searchEventTerm}
                                startAdornment
                                searchTermChange={searchEventTermChange}
                                placeholder={t('manageResponsePLan.filter3chars')}
                                id='searchEventsInput'
                            />
                            {showSearchEventReset && (
                                <ResponsePlanDialogResetButton onClick={resetEventsClick} variant='contained'>
                                    <Typography variant='button'>{t('manageResponsePLan.reset')}</Typography>
                                </ResponsePlanDialogResetButton>
                            )}
                        </ResponsePlanSearchTermWrapper>
                        <ResponsePlanDialogListWrapper>
                            {Array.isArray(eventsFiltered) && (
                                <FormControl component='fieldset'>
                                    <FormGroup>
                                        {eventsFiltered.map((event: any) => {
                                            return (
                                                <React.Fragment key={event.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedEvents.includes(event.id)}
                                                                onChange={handleCheckedEvent}
                                                                value={event.id}
                                                                color='primary'
                                                            />
                                                        }
                                                        label={`${event.name}`}
                                                    />
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                </FormControl>
                            )}
                        </ResponsePlanDialogListWrapper>
                    </ResponsePlanSelectActionsEventsWrapper>
                    <ResponsePlanSelectActionsEventsWrapper>
                        <Typography variant='h2'>{t('manageResponsePLan.actions')}</Typography>
                        <ResponsePlanSearchTermWrapper>
                            <SearchTermComponent
                                value={searchActionTerm}
                                startAdornment
                                searchTermChange={searchActionTermChange}
                                placeholder={t('manageResponsePLan.filter3chars')}
                                id='searchEventsInput'
                            />
                            {showSearchActionReset && (
                                <ResponsePlanDialogResetButton onClick={resetActionsClick} variant='contained'>
                                    <Typography variant='button'>{t('manageResponsePLan.reset')}</Typography>
                                </ResponsePlanDialogResetButton>
                            )}
                        </ResponsePlanSearchTermWrapper>
                        <ResponsePlanDialogListWrapper>
                            {Array.isArray(actionsFiltered) && (
                                <FormControl component='fieldset'>
                                    <FormGroup>
                                        {actionsFiltered.map((action: any) => {
                                            return (
                                                <React.Fragment key={action.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedActions.includes(action.id)}
                                                                onChange={handleCheckedAction}
                                                                value={action.id}
                                                                color='primary'
                                                            />
                                                        }
                                                        label={`${action.name}`}
                                                    />
                                                </React.Fragment>
                                            )
                                        })}
                                    </FormGroup>
                                </FormControl>
                            )}
                        </ResponsePlanDialogListWrapper>
                    </ResponsePlanSelectActionsEventsWrapper>
                </ResponsePlanDialogEventsActionsWrapper>
            </ResponsePlanDialogContentStyled>
            <ResponsePlanDialogButtonsWrapper>
                <ResponsePlanDialogButton onClick={handleClose} variant='text'>
                    <Typography variant='button' color='primary'>
                        {t('buttons.cancel')}
                    </Typography>
                </ResponsePlanDialogButton>
                <ResponsePlanDialogButton
                    disabled={!enableRunButton || invalidName || disabled}
                    onClick={newResponsePlanHandler}
                    variant='contained'>
                    {(postingData && <PulseLoader size={8} margin={2} color='#FFF' />) || (
                        <Typography variant='button'>{t('buttons.create')}</Typography>
                    )}
                </ResponsePlanDialogButton>
            </ResponsePlanDialogButtonsWrapper>
            {showFeedback && (
                <ResponsePlanDialogSnackbarStyled
                    open={showFeedback}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={feedback.duration}
                    onClose={handleFeedbackClose}>
                    <Alert onClose={handleFeedbackClose} severity={feedback.type} sx={{ width: '100%' }}>
                        {feedback.message}
                    </Alert>
                </ResponsePlanDialogSnackbarStyled>
            )}
        </Dialog>
    )
}
