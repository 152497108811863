import { IKpiMapped } from './kpiComparisonTable/KpiComparisonTableTypes'
import { IRPKpiList } from './kpisComparison/kpiComparisonTypes'
import { getKpisConverted } from '../../../../helpers/kpiHelper'

export const parseKPIs = (kpiList: IRPKpiList, RPsNames: string[], unitsSystem: string): IKpiMapped  => {

    let KpisParsed: IKpiMapped = {}
    RPsNames.forEach(rp => {
        const kpisConverted = getKpisConverted(unitsSystem, kpiList[rp])

        kpisConverted.forEach((kpi) => {
            if (!KpisParsed[kpi.name]) {
                KpisParsed[kpi.name] = {
                    name: kpi.name,
                    kpiRp : {
                        [rp]: {
                            value: kpi.value,
                            horizons: 1,
                        },
                    },
                    units: kpi.units,
                    target: kpi.target,
                }
            } else {
                if (!KpisParsed[kpi.name].kpiRp[rp]){
                    KpisParsed[kpi.name].kpiRp[rp] = {
                        value: kpi.value,
                        horizons: 1,
                    }
                } else {
                    KpisParsed[kpi.name].kpiRp[rp].value = (KpisParsed[kpi.name].kpiRp[rp].value + kpi.value)
                    KpisParsed[kpi.name].kpiRp[rp].horizons = (KpisParsed[kpi.name].kpiRp[rp].horizons + 1)
                }
            }
        })
    })

    return KpisParsed
}

export  const isRpSelected = (responsePlanId: string, selectedResponsePlans: string[]): boolean => {
    return selectedResponsePlans.includes(responsePlanId)
}

export const sortRp = (a: string, b: string, selectedResponsePlans: string[]): number => {
    if (!isRpSelected(a, selectedResponsePlans) && isRpSelected(b, selectedResponsePlans))
        return 1
    if (isRpSelected(a, selectedResponsePlans) && !isRpSelected(b, selectedResponsePlans))
        return -1
    return 0
}
