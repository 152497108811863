import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import {
    CloseRounded as CloseIcon,
    ArrowRightAltRounded,
    Delete as DeleteIcon,
    AccessTime as AccessTimeIcon,
} from '@mui/icons-material'

import {
    Alert,
    Dialog,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useAppSelector } from '../../app/hooks'
import {
    createNewEvent,
    deleteEvent,
    selectFeatureEventSelected,
    selectOpenEventsDialog,
    setOpenEventsDialog,
    updateEvent,
} from '../eventsToolbar/store/eventsSlice'
import {
    getNames,
    selectAvailableNames,
    selectConfig,
    selectEpoch,
    selectNowTime,
    selectTimeZone,
} from '../core/coreSlice'
import { UNIT_TYPE, UNITS_BY_SYSTEM } from '../../constants/unitsConstants'
import { converterToImperialSystem, converterToMetricSystem } from '../../helpers/unitsHelper'
import { DateTime } from 'luxon'
import { addTimeToDate, humanizeDuration } from '../../helpers/DateTimeHelper'
import CustomIcon from '../../components/customIcon/CustomIcon'
import {
    DurationText,
    EventsDialogButton,
    EventsDialogButtonsWrapper,
    EventsDialogCheckboxStyle,
    EventsDialogContentStyled,
    EventsDialogEditTimesTitle,
    EventsDialogEditTimesWrapper,
    EventsDialogIconTitleWrapper,
    EventsDialogLanesWrapper,
    EventsDialogRadioGroupWrapper,
    EventsDialogSectionIdText,
    EventsDialogSeparator,
    EventsDialogSlider,
    EventsDialogSnackbarStyled,
    EventsDialogTimesWrapper,
    EventsDialogTitle,
    EventsDialogTitleInfoWrapper,
    EventsDialogTitleStyled,
    EventsDialogTitleWrapper,
    LaneEditInfoWrapper,
    LaneInfoWrapper,
    RenderInputStyled,
} from './eventsDialogStyled'
import { WHITE } from '../../theme'
import { useTranslation } from 'react-i18next'

const minDistance = 10

export const EventsDialog: React.FC = () => {
    const { t, i18n } = useTranslation()
    const _featureEventSelected = useAppSelector(selectFeatureEventSelected)
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _epoch: number = useAppSelector(selectEpoch)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _nowTime = useAppSelector(selectNowTime)
    const _availableNames = useAppSelector(selectAvailableNames)
    const _openEventsDialog = useAppSelector(selectOpenEventsDialog)

    const [postingData, setPostingData] = useState<boolean>(false)
    const [dialogTittle, setDialogTittle] = useState<string>('New Event')
    const [updating, setUpdating] = useState<boolean>(false)
    const [eventName, setEventName] = useState<string>('')
    const [lanes, setLanes] = useState<any[]>([])
    const [eventType, setEventType] = useState<string>('real')
    const [duration, setDuration] = useState<string>('')
    const [selectedStart, setSelectedStart] = useState<DateTime>(DateTime.fromMillis(_epoch, { zone: _timeZone }))
    const [selectedEnd, setSelectedEnd] = useState<DateTime>(
        addTimeToDate(DateTime.fromMillis(_epoch, { zone: _timeZone }), 'hours', 2)
    )
    const [micro, setMicro] = useState<boolean>(false)
    const [position, setPosition] = useState<number[]>([10, 90])
    const [maxPosition, setMaxPosition] = useState<number>(100)
    const [positionMarks, setPositionMarks] = useState<any[]>()
    const [sectionId, setSectionId] = useState<number>()
    const [eventId, setEventId] = useState<number>()
    const [disabled, setDisabled] = useState<boolean>(false)
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [feedback, setFeedback] = useState<{
        message: string
        type?: 'success' | 'info' | 'warning' | 'error'
        duration: number
    }>({
        message: '',
        duration: 0,
    })
    const dispatch = useDispatch()

    const { units } = _moduleConfig

    useEffect(() => {
        dispatch(getNames('event'))
    }, [dispatch])

    useEffect(() => {
        if (_featureEventSelected.source.includes('events')) {
            retrieveEvent(_featureEventSelected)
        } else {
            setSectionId(_featureEventSelected.id)
            initLaneMatrix(_featureEventSelected)
            initDateTimePickers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_featureEventSelected])

    const invalidName: boolean = useMemo(
        () => !eventId && _availableNames.event.map(name => name.toLowerCase()).includes(eventName.toLowerCase()),
        [_availableNames, eventName, eventId]
    )

    const retrieveEvent = (feature: any) => {
        if (feature && feature.properties) {
            const {
                id,
                lanes,
                start_position,
                end_position,
                section_lenght,
                event_type,
                name,
                is_micro,
                section_id,
                start_time,
                end_time,
            } = feature.properties

            const lanesJSON = JSON.parse(lanes)
            const start = DateTime.fromMillis(start_time, { zone: _timeZone })
            const end = DateTime.fromMillis(end_time, { zone: _timeZone })
            const now = DateTime.fromMillis(_nowTime, { zone: _timeZone })
            const laneFoundation = lanesJSON.map((lane: any) => {
                return {
                    id: lane.id,
                    open: lane.open,
                    speed: Number.parseFloat(
                        units === 'metric'
                            ? Number(lane.speed).toFixed(0)
                            : Number(converterToImperialSystem(UNIT_TYPE.speed, Number(lane.speed))).toFixed(0)
                    ),
                    speed_default: Number.parseFloat(
                        units === 'metric'
                            ? Number(lane.speed_default).toFixed(0)
                            : Number(converterToImperialSystem(UNIT_TYPE.speed, Number(lane.speed_default))).toFixed(0)
                    ),
                }
            })
            const sectionLenghtUs =
                units === 'metric'
                    ? Number(section_lenght)
                    : Number(converterToImperialSystem(UNIT_TYPE.distance, Number(section_lenght)))
            const startPositionUs =
                units === 'metric'
                    ? Number(start_position)
                    : Number(converterToImperialSystem(UNIT_TYPE.distance, Number(start_position)))
            const endPositionUs =
                units === 'metric'
                    ? Number(end_position)
                    : Number(converterToImperialSystem(UNIT_TYPE.distance, Number(end_position)))

            setDisabled(now >= end)
            setUpdating(true)
            setDialogTittle('Update Event')
            setEventId(id)
            setEventName(name)
            setEventType(event_type)
            setMicro(is_micro)
            setSectionId(section_id)
            setLanes(laneFoundation)
            setSelectedStart(start)
            setSelectedEnd(end)
            updateEventDuration(end, start, true)
            setMaxPosition(sectionLenghtUs)
            setPosition([startPositionUs, endPositionUs])
            setPositionMarks([
                { value: 0, label: `0${UNITS_BY_SYSTEM[units].distance}` },
                { value: sectionLenghtUs, label: `${sectionLenghtUs}${UNITS_BY_SYSTEM[units].distance}` },
            ])
        } else {
            showEventFeedback('Could not retrieve event properties', 'warning')
        }
    }

    const { time: timeFormat, date: dateFormat, dateTime: dateTimeFormat } = _moduleConfig.date_format

    const valuePositionText = (value: number) => {
        return `${value}${UNITS_BY_SYSTEM[units].distance}`
    }

    const getLanesInfo = (feature: any) => {
        if (feature && feature.properties) {
            const attrs = JSON.parse(feature.properties.attributes)
            const nbLanes = attrs['GKSection::nbLanes']
            const speed = attrs['GKSection::speedAtt']
            if (nbLanes && speed) {
                const length = attrs['GKSection::totalLength']
                const micro = attrs['is_micro']
                if (!length || !micro) {
                    showEventFeedback('Missing attributtes on lanes', 'warning')
                }
                return attrs && [nbLanes, speed, length ? length : 0, micro ? micro : false]
            } else {
                showEventFeedback('Could not get lanes', 'warning')
            }
        } else {
            showEventFeedback('Could not get lanes', 'warning')
        }
    }

    const initLaneMatrix = (feature: any) => {
        const laneFoundation = []
        const lanesInfo = getLanesInfo(feature)
        if (lanesInfo) {
            for (let i = 0; i < lanesInfo[0]; i++) {
                const speedValue =
                    units === 'metric'
                        ? Number(lanesInfo[1]).toFixed(0)
                        : Number(converterToImperialSystem(UNIT_TYPE.speed, lanesInfo[1])).toFixed(0)
                laneFoundation.push({
                    id: i,
                    open: true,
                    speed: Number.parseFloat(speedValue),
                    speed_default: Number.parseFloat(speedValue),
                })
            }
            const p =
                units === 'metric'
                    ? Number(lanesInfo[2]).toFixed(0)
                    : Number(converterToImperialSystem(UNIT_TYPE.speed, lanesInfo[2])).toFixed(0)
            setLanes(laneFoundation)
            setMicro(lanesInfo[3])
            setMaxPosition(Number.parseFloat(p))
            setPosition([0, Number.parseFloat(p)])
            setPositionMarks([
                { value: 0, label: `0${UNITS_BY_SYSTEM[units].distance}` },
                { value: p, label: `${p}${UNITS_BY_SYSTEM[units].distance}` },
            ])
        }
    }

    const initDateTimePickers = () => {
        const dateTimeEpoch = DateTime.fromMillis(_epoch, { zone: _timeZone })
        const dateTimeNowTime = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        const start = dateTimeEpoch > dateTimeNowTime ? dateTimeEpoch : dateTimeNowTime
        const end = addTimeToDate(start, 'hours', 2)
        setSelectedStart(start)
        setSelectedEnd(end)
        updateEventDuration(end, start, updating)
    }

    const isValidEvent = (event: IEventNetwork) => {
        return (
            event &&
            event.name !== undefined &&
            event.name !== '' &&
            event.sectionId !== undefined &&
            event.lanes &&
            event.lanes.length > 0 &&
            event.end &&
            event.end > event.start &&
            (updating ? event.end > _nowTime : true) &&
            (!updating ? event.start >= _nowTime : true)
        )
    }

    const isValidSpeed = (event: IEventNetwork) => {
        let res: boolean = true
        if (event && event.lanes && event.lanes.length > 0) {
            for (let i = 0; i < event.lanes.length; i++) {
                if (event.lanes[i].speed > event.lanes[i].speed_default) {
                    res = false
                }
            }
        }
        return res
    }

    const isAnyClosedOrLimited = (event: IEventNetwork) => {
        let res: boolean = false
        if (event && event.lanes && event.lanes.length > 0) {
            for (let i = 0; i < event.lanes.length; i++) {
                if (!event.lanes[i].open || event.lanes[i].speed < event.lanes[i].speed_default) {
                    res = true
                }
            }
        }
        return res
    }

    const showEventFeedback = (message: string, type: 'success' | 'info' | 'warning' | 'error' = 'info') => {
        setShowFeedback(true)
        setFeedback({
            message: message,
            type: type,
            duration: 3000,
        })
        setTimeout(() => {
            setShowFeedback(false)
        }, 3000)
    }

    const handleLanesSelection = (event: any) => {
        if (event && event.target) {
            const laneId = Number.parseInt(event.target.value)
            const nextLanes = lanes.map(lane => {
                if (lane.id === laneId) {
                    return {
                        ...lane,
                        open: event.target.checked,
                    }
                } else {
                    return {
                        ...lane,
                    }
                }
            })
            setLanes(nextLanes)
        }
    }

    const handleClose = () => {
        setPostingData(false)
        dispatch(setOpenEventsDialog(false))
        setShowFeedback(false)
    }

    const setLaneSpeed = (event: any, laneId: any) => {
        const nextLanes = lanes.map(lane => {
            const inputSpeed = Number.parseInt(event.target.value)
            if (lane.id === laneId) {
                return {
                    ...lane,
                    speed: inputSpeed,
                }
            } else {
                return {
                    ...lane,
                }
            }
        })
        setLanes(nextLanes)
    }

    const handleStartChange = (date: DateTime) => {
        const nowTimeDate = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        if (date <= nowTimeDate) {
            setSelectedStart(nowTimeDate)
        } else {
            setSelectedStart(date)
        }
        updateEventDuration(selectedEnd, date, updating)
    }

    const handleEndChange = (date: DateTime) => {
        if (date <= nowTimeDate) {
            setSelectedEnd(nowTimeDate)
        } else {
            setSelectedEnd(date)
        }
        updateEventDuration(date, selectedStart, updating)
    }
    const validateEndTime = (nowTime: DateTime, endTime: DateTime, startTime: DateTime) => {
        if (endTime > nowTime) {
            if (endTime <= startTime) {
                setDuration('End time must be higher than start time')
            } else {
                const _duration = endTime.diff(startTime, ['months', 'days', 'hours', 'minutes'])
                setDuration(humanizeDuration(_duration))
            }
        } else {
            setDuration('End time must be higher than ' + nowTime.toFormat(dateTimeFormat))
        }
    }

    const updateEventDuration = (endTime: DateTime, startTime: DateTime, edit: boolean) => {
        const now = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        if (!edit) {
            if (startTime >= now) {
                validateEndTime(now, endTime, startTime)
            } else {
                setDuration('Start time must be higher than ' + now.toFormat(dateTimeFormat))
            }
        } else {
            validateEndTime(now, endTime, startTime)
        }
    }

    const deleteHandler = () => {
        const networkEvent: IEventNetwork = {
            id: eventId,
            name: eventName,
            start: selectedStart.toMillis(),
        }

        if (selectedStart > DateTime.fromMillis(_nowTime, { zone: _timeZone })) {
            dispatch(deleteEvent(networkEvent))
            handleClose()
        } else {
            showEventFeedback('Cannot delete an active event', 'warning')
        }
    }

    const getCurrentNetworkEvent = (): IEventNetwork => {
        const _closedLanes: string[] = lanes.filter(lane => lane.open === false).map(lane => lane.id)
        const laneFoundation = lanes.map((lane: any) => {
            return {
                id: lane.id,
                open: lane.open,
                speed: Number.parseFloat(
                    units === 'metric'
                        ? Number(lane.speed).toFixed(0)
                        : converterToMetricSystem(UNIT_TYPE.speed, Number(lane.speed)).toFixed(0)
                ),
                speed_default: Number.parseFloat(
                    units === 'metric'
                        ? Number(lane.speed_default).toFixed(0)
                        : converterToMetricSystem(UNIT_TYPE.speed, Number(lane.speed_default)).toFixed(0)
                ),
            }
        })
        const sectionLenghtUs =
            units === 'metric' ? maxPosition : Number(converterToMetricSystem(UNIT_TYPE.distance, Number(maxPosition)))
        const startPositionUs =
            units === 'metric' ? position[0] : Number(converterToMetricSystem(UNIT_TYPE.distance, Number(position[0])))
        const endPositionUs =
            units === 'metric'
                ? position[1]
                : Number(converterToImperialSystem(UNIT_TYPE.distance, Number(position[1])))

        return {
            id: eventId,
            name: eventName,
            sectionId: sectionId,
            closedLanes: _closedLanes,
            lanes: laneFoundation,
            start: selectedStart.toMillis(),
            end: selectedEnd.toMillis(),
            type: eventType,
            location: _featureEventSelected.coords,
            startPosition: startPositionUs,
            endPosition: endPositionUs,
            sectionLenght: sectionLenghtUs,
            isMicro: micro,
        }
    }

    const newEventHandler = () => {
        const networkEvent = getCurrentNetworkEvent()
        if (!isValidEvent(networkEvent)) {
            showEventFeedback('Invalid event, check values', 'warning')
        } else if (!isValidSpeed(networkEvent)) {
            showEventFeedback('Invalid event, speed must be lower or equal to lane default', 'warning')
        } else if (!isAnyClosedOrLimited(networkEvent)) {
            showEventFeedback('Invalid event, at least one lane closed or limited is required', 'warning')
        } else {
            if (updating) {
                dispatch(updateEvent(networkEvent))
                handleClose()
            } else if (networkEvent.start >= _nowTime) {
                dispatch(createNewEvent(networkEvent))
                handleClose()
            } else {
                showEventFeedback('start must be grater or equal than now time', 'warning')
            }
        }
    }

    const handleChangePosition = (event: any, value: any) => {
        if (!Array.isArray(value)) {
            return
        }

        if (value[1] - value[0] < minDistance) {
            const clamped = Math.min(value[0], 100 - minDistance)
            setPosition([clamped, clamped + minDistance])
        } else {
            setPosition(value as number[])
        }
    }

    const handleFeedbackClose = () => {
        setShowFeedback(false)
    }

    const timeRenderInput = (params: TextFieldProps, date: DateTime, format: string) => {
        if (params.inputProps && params.inputProps.value) {
            params.inputProps = Object.assign({}, params.inputProps, {
                value: date.setZone(_timeZone).toFormat(format),
            })
        }
        return <RenderInputStyled {...params} variant='standard' InputProps={{ disableUnderline: true }} />
    }

    const nowTimeDate = DateTime.fromMillis(_nowTime, { zone: _timeZone })
    const minStartTime =
        selectedStart > nowTimeDate && selectedStart.day > nowTimeDate.day
            ? DateTime.fromMillis(_nowTime, { zone: _timeZone }).startOf('day')
            : DateTime.fromMillis(_nowTime, { zone: _timeZone })

    const minEndTime =
        selectedEnd > nowTimeDate && selectedEnd.day > nowTimeDate.day
            ? DateTime.fromMillis(_nowTime, { zone: _timeZone }).startOf('day')
            : DateTime.fromMillis(_nowTime, { zone: _timeZone })

    return (
        <LocalizationProvider
            localeText={{ cancelButtonLabel: t('clockButtons.cancel'), okButtonLabel: t('clockButtons.ok') }}
            dateAdapter={AdapterLuxon}
            adapterLocale={i18n.language}>
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose()
                    }
                }}
                aria-labelledby='event-dialog-title'
                open={_openEventsDialog}
                disableEscapeKeyDown={true}>
                <EventsDialogTitleStyled id='event-dialog-title'>
                    <EventsDialogTitleInfoWrapper>
                        <EventsDialogTitleWrapper>
                            <EventsDialogIconTitleWrapper>
                                <CustomIcon name='eventNoFill' style={{ marginTop: '0' }} />
                                <EventsDialogTitle variant='h2'>{dialogTittle}</EventsDialogTitle>
                            </EventsDialogIconTitleWrapper>
                            {updating && (
                                <IconButton
                                    aria-label='delete'
                                    onClick={() => deleteHandler()}
                                    disabled={
                                        disabled || selectedStart <= DateTime.fromMillis(_nowTime, { zone: _timeZone })
                                    }
                                    style={{ width: '30px', height: '30px' }}>
                                    <DeleteIcon
                                        color={
                                            disabled ||
                                            selectedStart <= DateTime.fromMillis(_nowTime, { zone: _timeZone })
                                                ? 'disabled'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                            )}
                        </EventsDialogTitleWrapper>
                        <EventsDialogSectionIdText>{`Section Id: ${sectionId}`}</EventsDialogSectionIdText>
                    </EventsDialogTitleInfoWrapper>
                    <EventsDialogSeparator />
                </EventsDialogTitleStyled>
                <EventsDialogContentStyled id='event-dialog-content'>
                    <TextField
                        id='eventName'
                        value={eventName || ''}
                        label={'Name'}
                        variant={'standard'}
                        disabled={updating}
                        autoFocus={true}
                        onChange={e => setEventName(e.target.value)}
                        error={invalidName}
                        helperText={invalidName ? 'Name already exists' : null}
                        style={{
                            width: '100%',
                            marginBottom: '20px',
                        }}
                    />
                    <Typography variant='h5'>{'Type of event'}</Typography>
                    <RadioGroup
                        aria-label='event-type'
                        name='type'
                        value={eventType}
                        onChange={e => setEventType(e.target.value)}
                        style={{
                            marginBottom: '20px',
                        }}>
                        <EventsDialogRadioGroupWrapper>
                            <FormControlLabel
                                value='real'
                                disabled={disabled}
                                control={<Radio color='primary' />}
                                label='Real event'
                                labelPlacement='end'
                            />
                            <FormControlLabel
                                value='test'
                                disabled={disabled}
                                control={<Radio color='primary' />}
                                label='Test event'
                                labelPlacement='end'
                            />
                        </EventsDialogRadioGroupWrapper>
                    </RadioGroup>
                    {micro && <Typography variant='h5'>Position</Typography>}
                    {micro && (
                        <EventsDialogSlider
                            className='live-slider'
                            disabled={disabled}
                            aria-labelledby='range-position'
                            value={position}
                            onChange={handleChangePosition}
                            getAriaValueText={valuePositionText}
                            valueLabelFormat={valuePositionText}
                            valueLabelDisplay='on'
                            max={maxPosition}
                            marks={positionMarks}
                            step={0.1}
                        />
                    )}
                    <Typography variant='h5'>{'Lanes'}</Typography>
                    <EventsDialogLanesWrapper>
                        {lanes.map(lane => {
                            return (
                                <LaneInfoWrapper key={lane.id}>
                                    <EventsDialogCheckboxStyle
                                        laneopen={lane.open ? 0 : 1}
                                        checked={lane.open}
                                        disabled={disabled}
                                        value={lane.id}
                                        icon={<CloseIcon style={{ color: WHITE }} />}
                                        checkedIcon={<ArrowRightAltRounded style={{ color: WHITE }} />}
                                        onChange={handleLanesSelection}
                                    />
                                    <LaneEditInfoWrapper>
                                        <TextField
                                            id={`laneSpeed-${lane.id}`}
                                            variant={'standard'}
                                            value={lane.speed || ''}
                                            disabled={!lane.open || disabled}
                                            onChange={e => setLaneSpeed(e, lane.id)}
                                            style={{ width: '35px' }}
                                        />
                                        <Typography variant='body2' style={{ fontSize: 'smaller' }}>
                                            {UNITS_BY_SYSTEM[units].speed}
                                        </Typography>
                                    </LaneEditInfoWrapper>
                                </LaneInfoWrapper>
                            )
                        })}
                    </EventsDialogLanesWrapper>
                    <EventsDialogSeparator />
                    <EventsDialogTimesWrapper>
                        <AccessTimeIcon />
                        <EventsDialogEditTimesWrapper>
                            <EventsDialogEditTimesTitle variant='body1'>{'Start'}</EventsDialogEditTimesTitle>
                            <MobileTimePicker
                                value={selectedStart}
                                onChange={(date: any) => handleStartChange(date)}
                                onAccept={(date: any) => handleStartChange(date)}
                                ampm={false}
                                showToolbar={false}
                                minutesStep={5}
                                minTime={minStartTime}
                                renderInput={(params: any) => timeRenderInput(params, selectedStart, timeFormat)}
                                disabled={
                                    disabled || selectedStart < DateTime.fromMillis(_nowTime, { zone: _timeZone })
                                }
                            />
                            <MobileDatePicker
                                views={['year', 'month', 'day']}
                                value={selectedStart}
                                minDate={DateTime.fromMillis(_nowTime, { zone: _timeZone })}
                                onChange={(date: any) => handleStartChange(date)}
                                onAccept={(date: any) => handleStartChange(date)}
                                showToolbar={false}
                                renderInput={(params: any) => timeRenderInput(params, selectedStart, dateFormat)}
                                disabled={
                                    disabled || selectedStart < DateTime.fromMillis(_nowTime, { zone: _timeZone })
                                }
                            />
                        </EventsDialogEditTimesWrapper>
                        <EventsDialogEditTimesWrapper>
                            <EventsDialogEditTimesTitle variant='body1'>{'End'}</EventsDialogEditTimesTitle>
                            <MobileTimePicker
                                value={selectedEnd}
                                onChange={(date: any) => handleEndChange(date)}
                                onAccept={(date: any) => handleEndChange(date)}
                                ampm={false}
                                showToolbar={false}
                                minutesStep={5}
                                minTime={minEndTime}
                                renderInput={(params: any) => timeRenderInput(params, selectedEnd, timeFormat)}
                                disabled={disabled}
                            />
                            <MobileDatePicker
                                views={['year', 'month', 'day']}
                                value={selectedEnd}
                                minDate={DateTime.fromMillis(_nowTime, { zone: _timeZone })}
                                onChange={(date: any) => handleEndChange(date)}
                                onAccept={(date: any) => handleEndChange(date)}
                                showToolbar={false}
                                renderInput={(params: any) => timeRenderInput(params, selectedEnd, dateFormat)}
                                disabled={disabled}
                            />
                        </EventsDialogEditTimesWrapper>
                    </EventsDialogTimesWrapper>
                    <DurationText variant='h5'>{`Duration: ${duration}`}</DurationText>
                    <EventsDialogButtonsWrapper>
                        <EventsDialogButton onClick={handleClose} variant='text'>
                            <Typography variant='button' color='primary'>
                                {'Cancel'}
                            </Typography>
                        </EventsDialogButton>
                        <EventsDialogButton
                            disabled={disabled || invalidName}
                            onClick={newEventHandler}
                            variant='contained'>
                            {(postingData && <PulseLoader size={8} margin={2} color='#FFF' />) || (
                                <Typography variant='button'>{'Ok'}</Typography>
                            )}
                        </EventsDialogButton>
                    </EventsDialogButtonsWrapper>
                    {showFeedback && !disabled && (
                        <EventsDialogSnackbarStyled
                            open={showFeedback}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            autoHideDuration={feedback.duration}
                            onClose={handleFeedbackClose}>
                            <Alert onClose={handleFeedbackClose} severity={feedback.type} sx={{ width: '100%' }}>
                                {feedback.message}
                            </Alert>
                        </EventsDialogSnackbarStyled>
                    )}
                </EventsDialogContentStyled>
            </Dialog>
        </LocalizationProvider>
    )
}
