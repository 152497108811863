import { all, AllEffect, call, CallEffect, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosError, AxiosResponse } from 'axios'
import {
    getAllStationsRequest,
    getAverageCleanDataRequest,
    getAveragePatternDataRequest,
    getPredictionVsRealRequest,
    getPredictionVsRealRequestDelayTime,
    getQMDMDataRequest,
    getStationCleanDataRequest,
    getStationPatternDataRequest,
    getVariabilityPatternDataRequest,
    getWeightedSensors,
} from '../../../services/qualityManager/qualityManagerService'
import {
    storeQmData,
    setLoadingQmData,
    getSimulatedVsRealQuality,
    getQMDMData,
    getQMDMAverages,
    getQMDMVariability,
    setQmError, getSimulatedVsRealQualityDelayTime, storeDelayTimeSubpaths, getAllStations, storeAllStations, getQMDMStation,
} from './qualityManagerDialogSlice'
import { QmOutputTypes } from '../qualityManagerDialogOptions'
import { selectAvailablePatterns } from '../../pattern/store/patternSlice'
import { selectMainConfig, selectTimeZone } from '../../core/coreSlice'
import { addTimeToDate } from '../../../helpers/DateTimeHelper'
import { DateTime } from 'luxon'

function* fetchSimulatedVsReal(action: { payload: ISimulatedVsRealQualityAction }): Generator<unknown, any, any> {
    try {
        const { visualisation, variable, from, to } = action.payload
        const outputOptions: IQMOutputType | undefined = QmOutputTypes.get(variable)
        if (outputOptions) {
            const params = {
                type: visualisation,
                variable: outputOptions.variable,
                statistic: outputOptions.statistic,
                object_type: outputOptions.object_type,
                from,
                to,
            }
            const qmData: IPredVsRealResponse = yield call(getPredictionVsRealRequest, params)
            yield put(storeQmData(qmData))
            yield put(setLoadingQmData(false))
        }
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchSimulatedVsReal error:', error)
    }
}
function* fetchSimulatedVsRealDelayTime(action: { payload: ISimulatedVsRealDelayTimeAction }): Generator<unknown, any, any> {
    try {
        const { from, to } = action.payload
        const params = {
            from,
            to: to - 1,
        }
        const subPaths: string[] = yield call(getWeightedSensors, 'delay_time')
        yield put(storeDelayTimeSubpaths(subPaths))
        const qmData: IPredVsRealResponse = yield call(getPredictionVsRealRequestDelayTime, params)
        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchSimulatedVsReal error:', error)
    }
}

function* fetchQMDMData(action: { payload: IQMDMDataAction }): Generator<unknown, any, any> {
    try {
        const { visualisation, variable, source, interval, from, to, filter } = action.payload
        const qmData: IQMDMResponse = yield call(getQMDMDataRequest, {
            type: visualisation,
            variable,
            realm: source,
            interval,
            from,
            to,
            filter,
        })
        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchAverages(action: { payload: IQMDMAverages }): Generator<unknown, any, any> {
    try {
        const { variable, t, n_hours } = action.payload
        const _availablePatterns: IPatternJson[] = yield select(selectAvailablePatterns)
        let fetch: { [p: string]: CallEffect<AxiosResponse<IQMAverageResponse, any>> } = {}

        _availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getAveragePatternDataRequest, {
                pattern_id: pattern.pattern_id,
                variable,
                t,
                n_hours,
            })
        })
        fetch['PM pred'] = call(getAveragePatternDataRequest, {
            variable,
            t,
            n_hours,
        })
        fetch['Clean'] = yield call(getAverageCleanDataRequest, {
            variable,
            t,
            n_hours,
        })
        const qmDatafetch = yield all(fetch)
        const _mainConfig: IMainConfig = yield select(selectMainConfig)
        const _timeZone: string = yield select(selectTimeZone)

        const cleanerInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60
        const minTime = DateTime.fromMillis(t, { zone: _timeZone }).startOf('day')
        const maxTime = DateTime.fromMillis(t, { zone: _timeZone }).endOf('day')
        const nullSeries: { time: number, avg: number | null }[] = []
        for (let i = minTime; i <= maxTime; i = addTimeToDate(i, 'minutes', cleanerInterval)) {
            nullSeries.push({ time: i.toMillis(), avg: null })
        }

        const nextClean = nullSeries.map((data: any) => {
            const indexFound = qmDatafetch['Clean'].findIndex((point: any) => point.time === data.time)
            if (indexFound !== -1) {
                return { time: data.time, avg: qmDatafetch['Clean'][indexFound].avg }
            } else {
                return data
            }
        })

        const nextPMPred = nullSeries.map((data: any) => {
            const indexFound = qmDatafetch['PM pred'].findIndex((point: any) => point.time === data.time)
            if (indexFound !== -1) {
                return { time: data.time, avg: qmDatafetch['PM pred'][indexFound].avg }
            } else {
                return data
            }
        })
        const nextPattern: any = []
        nullSeries.forEach((data: any) => {
            _availablePatterns.forEach(pattern => {
                if (!nextPattern[`Pattern ${pattern.pattern_id}`]) {
                    nextPattern[`Pattern ${pattern.pattern_id}`] = []
                }
                const indexFound = qmDatafetch[`Pattern ${pattern.pattern_id}`].findIndex((point: any) => point.time === data.time)
                if (indexFound !== -1) {
                     nextPattern[`Pattern ${pattern.pattern_id}`] = [
                        ...nextPattern[`Pattern ${pattern.pattern_id}`],
                        { time: data.time, avg: qmDatafetch[`Pattern ${pattern.pattern_id}`][indexFound].avg }
                    ]
                } else {
                    nextPattern[`Pattern ${pattern.pattern_id}`] = [
                        ...nextPattern[`Pattern ${pattern.pattern_id}`],
                        data
                    ]
                }
            })
        })
        let nextQmDatafetch = {
            'Clean': nextClean,
            'PM pred': nextPMPred,
            ...nextPattern
        }

        let qmData: any[] = []
        for (const [k, v] of Object.entries(nextQmDatafetch)) {
            if (v) {
                const items = v as IQMAverage[]
                items.forEach(item => {
                    if (qmData[item.time]) {
                        qmData[item.time] = qmData[item.time].concat({ key: k, avg: item.avg })
                    } else {
                        qmData[item.time] = [{ key: k, avg: item.avg }]
                    }
                })
            }
        }

        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchStationQmData(action: { payload: IQMDMStation }): Generator<unknown, any, any> {
    try {
        const { variable, t, n_hours, feature_id } = action.payload
        const _availablePatterns: IPatternJson[] = yield select(selectAvailablePatterns)
        let fetch: { [p: string]: CallEffect<AxiosResponse<IQMAverageResponse, any>> } = {}

        _availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getStationPatternDataRequest, {
                pattern_id: pattern.pattern_id,
                variable,
                t,
                n_hours,
                feature_id
            })
        })
        fetch['PM pred'] = call(getStationPatternDataRequest, {
            pattern_id: 0,
            variable,
            t,
            n_hours,
            feature_id
        })
        fetch['Clean'] = yield call(getStationCleanDataRequest, {
            variable,
            t,
            n_hours,
            feature_id,
        })
        const qmDatafetch = yield all(fetch)

        const _mainConfig: IMainConfig = yield select(selectMainConfig)
        const _timeZone: string = yield select(selectTimeZone)

        const cleanerInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60
        const minTime = DateTime.fromMillis(t, { zone: _timeZone }).startOf('day')
        const maxTime = DateTime.fromMillis(t, { zone: _timeZone }).endOf('day')
        const nullSeries: { time: number, avg: number | null }[] = []
        for (let i = minTime; i <= maxTime; i = addTimeToDate(i, 'minutes', cleanerInterval)) {
            nullSeries.push({ time: i.toMillis(), avg: null })
        }

        const nextClean = nullSeries.map((data: any) => {
            const indexFound = qmDatafetch['Clean'].findIndex((point: any) => point.time === data.time)
            if (indexFound !== -1) {
                return { time: qmDatafetch['Clean'][indexFound].time, avg: qmDatafetch['Clean'][indexFound].value }
            } else {
                return data
            }
        })

        const nextPMPred = nullSeries.map((data: any) => {
            const indexFound = qmDatafetch['PM pred'].findIndex((point: any) => point.time === data.time)
            if (indexFound !== -1) {
                return { time: qmDatafetch['PM pred'][indexFound].time, avg: qmDatafetch['PM pred'][indexFound].mean }
            } else {
                return data
            }
        })
        const nextPattern: any = []
        nullSeries.forEach((data: any) => {
            _availablePatterns.forEach(pattern => {
                if (!nextPattern[`Pattern ${pattern.pattern_id}`]) {
                    nextPattern[`Pattern ${pattern.pattern_id}`] = []
                }
                const indexFound = qmDatafetch[`Pattern ${pattern.pattern_id}`].findIndex((point: any) => point.time === data.time)
                if (indexFound !== -1) {
                    nextPattern[`Pattern ${pattern.pattern_id}`] = [
                        ...nextPattern[`Pattern ${pattern.pattern_id}`],
                        { time: data.time, avg: qmDatafetch[`Pattern ${pattern.pattern_id}`][indexFound].mean }
                    ]
                } else {
                    nextPattern[`Pattern ${pattern.pattern_id}`] = [
                        ...nextPattern[`Pattern ${pattern.pattern_id}`],
                        data
                    ]
                }
            })
        })
        let nextQmDatafetch = {
            'Clean': nextClean,
            'PM pred': nextPMPred,
            ...nextPattern
        }

        let qmData: any[] = []
        for (const [k, v] of Object.entries(nextQmDatafetch)) {
            if (v) {
                const items = v as IQMAverage[]
                items.forEach(item => {
                    if (qmData[item.time]) {
                        qmData[item.time] = qmData[item.time].concat({ key: k, avg: item.avg })
                    } else {
                        qmData[item.time] = [{ key: k, avg: item.avg }]
                    }
                })
            }
        }

        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchPatternVariability(action: { payload: IQMDMVariability }): Generator<unknown, any, any> {
    try {
        const { n_items, t } = action.payload
        const _availablePatterns: IPatternJson[] = yield select(selectAvailablePatterns)

        let fetch: { [p: string]: CallEffect<AxiosResponse<IQMVariabilityResponse, any>> } = {}

        _availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getVariabilityPatternDataRequest, {
                pattern_id: pattern.pattern_id,
                t,
                n_items,
            })
        })
        const qmDatafetch = yield all(fetch)
        let qmData: any[] = []
        for (const [k, v] of Object.entries(qmDatafetch)) {
            if (v) {
                const items = v as IQMVariability[]
                items.forEach(item => {
                    if (qmData[item.time]) {
                        qmData[item.time] = qmData[item.time].concat({ key: k, reliability: item.reliability })
                    } else {
                        qmData[item.time] = [{ key: k, reliability: item.reliability }]
                    }
                })
            }
        }

        yield put(storeQmData(qmData))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* fetchAllStations(): Generator<unknown, any, any> {
    try {
        const allStations = yield call(getAllStationsRequest)
        yield put(storeAllStations(allStations))
        yield put(setLoadingQmData(false))
    } catch (err) {
        const error = err as Error | AxiosError
        yield put(setQmError(error.message))
        console.error('fetchQMDMData error:', error)
    }
}

function* qualityManagerDialogSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(getSimulatedVsRealQuality, fetchSimulatedVsReal),
        yield takeLatest(getSimulatedVsRealQualityDelayTime, fetchSimulatedVsRealDelayTime),
        yield takeLatest(getQMDMData, fetchQMDMData),
        yield takeLatest(getQMDMAverages, fetchAverages),
        yield takeLatest(getQMDMStation, fetchStationQmData),
        yield takeLatest(getQMDMVariability, fetchPatternVariability),
        yield takeLatest(getAllStations, fetchAllStations)
    ])
}

export default qualityManagerDialogSaga
