import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ClockInputRender } from './clockInputRender'
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { addTimeToDate, subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { TextFieldProps } from '@mui/material'
import { ClockWrapper, ClockStyledIconButton, StyledMobileDateTimePicker } from './clockStyles'
import { useAppDispatch, useAppSelector, useFetchingData } from '../../app/hooks'
import {
    selectConfig,
    selectEpoch,
    selectEpochChange,
    selectNowTime,
    selectTimeZone,
    storeEpoch,
    storeEpochChange,
} from '../core/coreSlice'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { selectListEventsFlag, toggleListEvents } from '../mapbox/mapboxSlice'

const Clock: React.FC<IClockProps> = ({
    isDisabled = false,
    minutesStep = 0,
    longTimeStep = { number: 0, unit: 'hours' },
    showArrowProgress,
    updateDataWithNowTime,
    allowFuture,
    allowPast,
}: IClockProps) => {
    const { t, i18n } = useTranslation()
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _epoch = useAppSelector(selectEpoch)
    const _nowTime = useAppSelector(selectNowTime)
    const _epochChange = useAppSelector(selectEpochChange)
    const _lListEventsFlag = useAppSelector(selectListEventsFlag)
    const [value, setValue] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [maxDateTime, setMaxDateTime] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const [minDateTime, setMinDateTime] = useState(DateTime.fromMillis(_nowTime, { zone: _timeZone }))
    const dispatch = useAppDispatch()
    const _fetchingData = useFetchingData()

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_epoch, { zone: _timeZone })
        setValue(nextDate)
    }, [_epoch, _timeZone])

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_nowTime, { zone: _timeZone })
        if (_nowTime !== 0) {
            if (!_epochChange) {
                dispatch(storeEpoch(_nowTime))
                dispatch(storeEpochChange(true))
            } else {
                if (updateDataWithNowTime) {
                    dispatch(storeEpoch(_nowTime))
                }
            }
            if (!allowPast) {
                setMinDateTime(nextDate)
            }
            if (!allowFuture) {
                setMaxDateTime(nextDate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime, _timeZone])

    const handleAccept = (date: DateTime) => {
        saveDate(date)
        closeListEvents()
    }

    const handleChange = (date: DateTime) => {
        setValue(date)
    }

    const saveDate = (date: DateTime) => {
        dispatch(storeEpoch(date.toMillis()))
        setValue(date)
    }

    const goLongTimeBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
        closeListEvents()
    }

    const goStepBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
        closeListEvents()
    }

    const goStepForward = () => {
        const nextDate: DateTime = addTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
        closeListEvents()
    }

    const goLongTimeForward = () => {
        const nextDate: DateTime = addTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
        closeListEvents()
    }

    const disabledStepForward = allowFuture
        ? false
        : maxDateTime && addTimeToDate(value, 'minutes', minutesStep) > maxDateTime

    const disabledLongTimeForward = allowFuture
        ? false
        : maxDateTime && addTimeToDate(value, longTimeStep.unit, longTimeStep.number) > maxDateTime

    const disabledStepBack = allowPast
        ? false
        : minDateTime && subtractTimeToDate(value, 'minutes', minutesStep) < minDateTime

    const disabledLongTimeBack = allowPast
        ? false
        : minDateTime && subtractTimeToDate(value, longTimeStep.unit, longTimeStep.number) < minDateTime

    const renderInput = (params: TextFieldProps) => {
        let timeParams = { ...params }
        let dateParams = { ...params }

        if (timeParams?.inputProps?.value) {
            timeParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.time),
            }
        }
        if (dateParams?.inputProps?.value) {
            dateParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.date),
            }
        }

        return <ClockInputRender timeParams={timeParams} dateParams={dateParams} />
    }

    const closeListEvents = () => {
        if(_lListEventsFlag) {
            dispatch(toggleListEvents(false))
        }
    }

    const DatetimePicker = () => {
        if (!allowPast) {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    minDateTime={minDateTime}
                    minDate={minDateTime}
                    minTime={minDateTime}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        } else if (!allowFuture) {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    maxDateTime={maxDateTime}
                    maxDate={maxDateTime}
                    maxTime={maxDateTime}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        } else {
            return (
                <StyledMobileDateTimePicker
                    value={value}
                    ampm={false}
                    maxDateTime={maxDateTime}
                    maxDate={maxDateTime}
                    maxTime={maxDateTime}
                    showToolbar={false}
                    onAccept={(date: any) => handleAccept(date)}
                    onChange={(date: any) => handleChange(date)}
                    disabled={isDisabled || _fetchingData}
                    minutesStep={minutesStep}
                    DialogProps={{ style: { color: 'magenta' } }}
                    renderInput={(params: any) => renderInput(params)}
                    disableIgnoringDatePartForTimeValidation={true}
                    dayOfWeekFormatter={day => {
                        return i18n.resolvedLanguage?.includes('zh')
                            ? day.slice(1, 2).toUpperCase()
                            : day.slice(0, 1).toUpperCase()
                    }}
                />
            )
        }
    }

    return (
        <ClockWrapper>
            {showArrowProgress && (
                <>
                    <ClockStyledIconButton disabled={disabledLongTimeBack || _fetchingData} onClick={goLongTimeBack}>
                        <FirstPage />
                    </ClockStyledIconButton>
                    <ClockStyledIconButton disabled={disabledStepBack || _fetchingData} onClick={goStepBack}>
                        <ChevronLeft />
                    </ClockStyledIconButton>
                </>
            )}
            <LocalizationProvider
                localeText={{ cancelButtonLabel: t('clockButtons.cancel'), okButtonLabel: t('clockButtons.ok') }}
                dateAdapter={AdapterLuxon}
                adapterLocale={i18n.language}>
                {DatetimePicker()}
            </LocalizationProvider>
            {showArrowProgress && (
                <>
                    <ClockStyledIconButton disabled={disabledStepForward || _fetchingData} onClick={goStepForward}>
                        <ChevronRight />
                    </ClockStyledIconButton>
                    <ClockStyledIconButton
                        disabled={disabledLongTimeForward || _fetchingData}
                        onClick={goLongTimeForward}>
                        <LastPage />
                    </ClockStyledIconButton>
                </>
            )}
        </ClockWrapper>
    )
}

export default Clock
