import React, { useEffect, useState } from 'react'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { ActionsToolbarIcon, ActionsToolbarLabel, ActionsToolbarWrapper } from './actionsToolbarStyles'
import { useDispatch } from 'react-redux'
import { selectActionIsSelected, setActionSelected } from './store/actionsSlice'
import { toggleCreateTrafficActionNetwork } from '../mapbox/mapboxSlice'
import { useAppSelector } from '../../app/hooks'
import { useTranslation } from 'react-i18next'

const ActionsToolbar: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const _actionsIsSelected: boolean = useAppSelector(selectActionIsSelected)
    const [actionToolbarIsSelected, setActionToolbarIsSelected] = useState<boolean>(_actionsIsSelected)

    useEffect(() => {
        setActionToolbarIsSelected(_actionsIsSelected)
    }, [_actionsIsSelected])

    const handleActionsToolbarOnClick = () => {
        setActionToolbarIsSelected(!actionToolbarIsSelected)
        dispatch(setActionSelected(!actionToolbarIsSelected))
        dispatch(toggleCreateTrafficActionNetwork(0))
    }

    return (
        <>
            <ActionsToolbarWrapper onClick={handleActionsToolbarOnClick}>
                <ActionsToolbarIcon color={actionToolbarIsSelected ? 'secondary' : 'primary'}>
                    <CustomIcon name={actionToolbarIsSelected ? 'actionActive' : 'action'} />
                </ActionsToolbarIcon>
                <ActionsToolbarLabel className={`${actionToolbarIsSelected ? 'open-actionsToolbar-dialog' : ''}`}>
                    {t('manageActions.actions')}
                </ActionsToolbarLabel>
            </ActionsToolbarWrapper>
        </>
    )
}

export default ActionsToolbar
