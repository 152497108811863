import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './app/store'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.scss'
import './sentryInit'

interface CypressWithStore {
    store?: typeof store
}

declare global {
    interface Window {
        Cypress?: CypressWithStore
    }
}

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (typeof window !== 'undefined' && window.Cypress) {
    window.Cypress.store = store
}
