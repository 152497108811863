import { Button } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    initialEmptyFeatureCollectionState,
    restoreDataByMap,
    restoreEvents,
    restoreIncidents,
    restoreLayerVisibility,
    restoreRpActions,
    selectTrafficActionsNetworkFlag,
    storeActions,
    toggleCreateTrafficActionNetwork,
} from '../mapbox/mapboxSlice'
import { NavigatorButtonGroup, NavigatorCanvas } from './navigatorStyles'
import { storeEpochChange } from '../core/coreSlice'
import { restoreStationSelected } from '../stationDialog/store/stationSelectedSlice'
import { setSelectedEvaluation, initialSetSelectedEvaluationState } from '../evaluationsSelector/store/evaluationsSlice'
import { useTranslation } from 'react-i18next'
import { setEventsSelected } from '../eventsToolbar/store/eventsSlice'
import { restoreInitialEventsLisList } from '../listEventsModal/store/listEventsSlice'

const Navigator: React.FC<INavigatorProps> = (props: INavigatorProps) => {
    const { t } = useTranslation()
    const config: IModuleConfig = props.config
    const _showTrafficActionsNetwork: boolean = useAppSelector(selectTrafficActionsNetworkFlag)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [navigation, setNavigation] = React.useState<INavigationItem[]>([])
    const [activeRoute, setActiveRoute] = React.useState<string>('')

    useEffect(() => {
        const currentPath = window.location.pathname
        setActiveRoute(currentPath)
    }, [])

    useEffect(() => {
        setNavigation(
            config.modules
                .filter(({ type, name }: IModule): boolean => type === 'container' && name !== 'loader')
                .map((module: IModule): { url: string; name: string } => {
                    return { url: module.url, name: module.name }
                })
        )
    }, [config])

    const navigateTo = (url: string) => {
        if (!url.includes('landuse')) {
            dispatch(restoreStationSelected())
            dispatch(restoreLayerVisibility())
            dispatch(restoreDataByMap())
            dispatch(restoreIncidents())
            dispatch(restoreEvents())
            dispatch(restoreRpActions())
            dispatch(storeEpochChange(false))
            dispatch(setSelectedEvaluation(initialSetSelectedEvaluationState))
            dispatch(storeActions(initialEmptyFeatureCollectionState))
            dispatch(setEventsSelected(false))
            dispatch(restoreInitialEventsLisList())
        } else {
            dispatch(setSelectedEvaluation(initialSetSelectedEvaluationState))
        }
        if (_showTrafficActionsNetwork) {
            dispatch(toggleCreateTrafficActionNetwork(0))
        }
        navigate(url)
    }

    return (
        <NavigatorCanvas>
            <NavigatorButtonGroup variant='contained' disableElevation={true}>
                {navigation.map((navItem: INavigationItem) => {
                    return (
                        <Button
                            key={navItem.url}
                            onClick={() => navigateTo(navItem.url)}
                            color={activeRoute === navItem.url ? 'primary' : 'secondary'}>
                            {t(`navigator.${navItem.name}`)}
                        </Button>
                    )
                })}
            </NavigatorButtonGroup>
        </NavigatorCanvas>
    )
}
export default Navigator
