import {
    all,
    AllEffect,
    call,
    ForkEffect,
    put,
    takeEvery,
} from 'redux-saga/effects'
import { loadEventsLog, setFetchingEventsList, storeEventsLisRange, storeEventsList } from './listEventsSlice'
import { getLogListEvents } from '../../../services/listLogEvents/listLogEventsService'

function* fetchEventsLogList(action: any): any
{
    const eventsList = yield call(getLogListEvents, action.payload)
    yield put(storeEventsList(eventsList))
    yield put(storeEventsLisRange({from: action.payload.from, to: action.payload.to}))
    yield put(setFetchingEventsList(false))
}

function* listEventsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield all([yield takeEvery(loadEventsLog, fetchEventsLogList)])])
}

export default listEventsSaga
