import { Theme, createTheme } from '@mui/material'

export const NEXT_BLUE = '#1F80D4'
export const NEXT_DARK = '#0E4A7D'
export const NAVY_BLUE = '#09254C'
export const NAVY_BLUE_LOGO = '#051935'
export const OLIVE_ISH = '#009186'
export const OLIVE = '#00CDBE'
export const OLIVE_LIGHT = '#01FFEC'
export const MUSTARD = '#FFC401'
export const MUSTARD_DARK = '#C39500'
export const MUSTARD_DARKER = '#8E6C00'
export const RED_LIVE = '#EF442E'
export const SUBPATH_NODES = '#d33479'
export const RED_LIVE_OPAQUE = '#EF442E4D'
export const RED_LIVE_DARK = '#AF3121'
export const RED_LIVE_DARKER = '#681D13'
export const GREEN_LIGHT = '#02DD16'
export const GREEN = '#00960E'
export const GREEN_DARK = '#005908'
export const SMOKE = '#616161'
export const GREY = '#9e9e9e'
export const WYLD_PINK = '#D33479'
export const PALE_PINK = '#E4A098'
export const PURPLE_ISH = '#DDCBE3'
export const WHITE = '#FFFFFF'
export const TITLE_TOOLBAR = '#4C4C4C'
export const LIGHT_GREY = '#eeeeee'
export const NO_DATA = '#D8D8D8'
export const BORDER_ZOOM = '#7f7f7f'
export const TABLE_ROW = '#00000021'
export const LOADER_COLOR = '#E9A59D'
export const LANES_OPEN = '#00968B'
export const LANES_CLOSE = '#FF4040'
export const EXISTING_CONGESTION = '#7a2cc9'

const aimsunLiveTheme: Theme = createTheme({
    palette: {
        primary: {
            main: RED_LIVE,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: RED_LIVE,
        },
    },
    components: {
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: '1.6rem',
                    border: '3px solid',
                    borderColor: RED_LIVE,
                    padding: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '1.6rem',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    paddingLeft: '0.75rem',
                    paddingRight: '0.75rem',
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
    },
    typography: {
        body1: {
            fontFamily: 'AimsunMedium, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontSize: 16,
            lineHeight: 1.3,
        },
        body2: {
            fontFamily: 'AimsunMedium, Roboto, Sans-serif',
            color: GREY,
            fontSize: 16,
            lineHeight: 1.3,
        },
        button: {
            fontFamily: 'AimsunLight, Roboto, Sans-serif',
            color: WHITE,
            fontSize: 16,
        },
        caption: {
            fontFamily: 'AimsunLight, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 900,
            letterSpacing: 1,
        },
        h1: {
            fontFamily: 'AimsunBold, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontSize: 20,
            fontWeight: 800,
        },
        h2: {
            fontFamily: 'AimsunBold, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontSize: 18,
            fontWeight: 800,
            opacity: 1,
        },
        h4: {
            fontFamily: 'AimsunMedium, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontSize: 18,
            marginBottom: '10px',
        },
        h5: {
            fontFamily: 'AimsunBold, Roboto, Sans-serif',
            color: SMOKE,
            fontSize: 12,
            opacity: 0.8,
        },
        h6: {
            fontFamily: 'AimsunLight, Roboto, Sans-serif',
            color: NAVY_BLUE,
            fontWeight: 900,
            letterSpacing: 1,
        },
    },
})

export default aimsunLiveTheme
