import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight, KeyboardTab, SkipNext, SkipPrevious } from '@mui/icons-material'
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Pagination,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material'
import { NAVY_BLUE, RED_LIVE, RED_LIVE_DARK, SMOKE } from '../../theme'

export const QmDialogTitleTypography = styled(Typography)`
    margin: 20px 20px 0;
`

export const QmDialogErrorTitleTypography = styled(Typography)`
    text-align: center;
    margin-top: 15%;
    font-size: 2.25rem;
    color: ${RED_LIVE};
`

export const QmDialogErrorTypography = styled(Typography)`
    text-align: center;
    font-size: 1rem;
    color: ${SMOKE};
`

export const QmDialogCloseButton = styled(IconButton)`
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${RED_LIVE_DARK};
`
export const QmDialogTabsBox = styled(Box)`
    position: absolute;
    right: 10%;
    top: 37px;
`

export const QmDialogTabsHorizonBox = styled(Box)`
    margin-top: 20px;
`

export const QmDialogTab = styled(Tab)({
    textTransform: 'capitalize',
    fontWeight: 900,
    color: NAVY_BLUE,
})

export const QmDialogTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        bottom: 10,
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 30,
        width: '100%',
        backgroundColor: RED_LIVE,
    },
})

export const QmDialogHorizonTab = styled(QmDialogTab)`
    width: 160px;
`

export const QmDialogInputLabelStyled = styled(InputLabel)`
    color: ${SMOKE};
    &.Mui-focused {
        color: ${SMOKE};
    }
`

export const QmDialogAutocompleteInputLabelStyled = styled(TextField)`
    label {
      color: ${SMOKE};
      &.Mui-focused {
        color: ${SMOKE};
      }
      top: -5px;
    }
`

export const QmDialogFormControlStyled = styled(FormControl)`
    width: 270px;
    padding-left: 13px;
    &.Mui-focused {
        color: ${NAVY_BLUE};
    }
    .source-selector {
        &.selected {
            background-color: ${NAVY_BLUE};
            opacity: 0.6;
        }
        &:after {
            border-bottom-color: inherit;
        }
    }
`

export const QmDialogSelectorWrapper = styled(Grid)`
    padding: 16px;
    position: relative;
`
export const QmDialogSelect = styled(Select)`
    margin-top: 10px;
    color: ${NAVY_BLUE};
`

export const QmDialogPickersWrapper = styled(Grid)`
    margin-top: 40px;
`
export const QmDialogPreviousWeek = styled(SkipPrevious)<{ disabled: boolean }>`
    margin-top: 24px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
export const QmDialogPreviousDay = styled(ChevronLeft)<{ disabled: boolean }>`
    margin-top: 24px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
export const QmDialogNextWeek = styled(SkipNext)<{ disabled: boolean }>`
    margin-top: 24px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
export const QmDialogNextDay = styled(ChevronRight)<{ disabled: boolean }>`
    margin-top: 24px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
export const QmDialogLatestData = styled(KeyboardTab)<{ disabled: boolean }>`
    margin-top: 24px;
    margin-left: 6px;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
export const QmDialogDatePickerLabel = styled(Typography)`
    text-align: center;
    &.MuiTypography-root {
        font-size: 13px;
    }
`
export const QmDialogClockDateInput = styled(TextField)`
    input {
        text-align: center;
    }
`
export const QmDialogDatePickerGeneralWrapper = styled(Grid)`
    padding: 0;
`
export const QmDialogDatePickerContainerWrapper = styled(Grid)`
    width: 310px;
`
export const QmDialogDatePickerWrapper = styled(Grid)`
    width: 90px;
`
export const QmDialogLoaderContainer = styled(Box)`
    display: flex;
    align-items: center;
    align-content: center;
    height: 70vh;
    justify-content: center;
`
export const QmDialogTableTypographyHeader = styled(Typography)({
    textTransform: 'capitalize',
    marginLeft: '24px',
    fontSize: '18px',
})

export const QmPagination = styled(Pagination)`
    text-align: center;
    margin: 0 auto;
    max-width: 350px;
    & li {
        margin-left: auto;
        margin-right: auto;
    }
`
export const QmMapContainerWrapper = styled(Box)`
    height: 80vh;
    width: 96vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: auto;
`

export const QmMapToolbarContainer = styled(Box)`
    display: flex;
    width: 40%;
    margin-bottom: 2vh;
`

export const QmDelayTimeDate = styled(Typography)`
    white-space: nowrap;
`

export const QmDelayTimeBlufaxPrediction = styled(Typography)`
  margin-left: 24px;
  white-space: nowrap;
`
