import React, { useEffect, useState } from 'react'
import CustomDialog from '../../../components/customDialog/customDialog'
import { Alert, Checkbox, InputAdornment, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import {
    DoNothingScenario,
    NewManageEvaluationDialogButton,
    NewManageEvaluationDialogButtonsWrapper,
    EventsDropDownForm,
    EventsInputLabel,
    SearchStrategiesForm,
    NewManageEvaluationResetButton,
    PrecodedResponsePlanTypography,
    ResponsePlanFormControlLabel,
    NewManageEvaluationContentWrapper,
} from './newManageEvaluationDialogStyles'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../app/hooks'
import { selectConfig, selectEpoch, selectPolicies, selectStrategies, selectTimeZone } from '../../core/coreSlice'
import { useDispatch } from 'react-redux'
import { InputSearch } from '../../../components/searchTermComponent/searchTermStyles'
import { Search as SearchIcon } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { PulseLoader } from 'react-spinners'
import { createNewStaticEvaluationManage } from '../store/evaluationsManageSlice'
import { selectEvaluationCreatedMessage, storeEvaluationCreatedMessage } from '../store/evaluationsSlice'
import { EventsDialogSnackbarStyled } from '../../eventsDialog/eventsDialogStyled'

const NewManageEvaluationDialog = ({
    open,
    onClose,
    allViewModes,
    setCreateEvaluationDialogCancel,
}: NewManageEvaluation) => {
    const { t } = useTranslation()
    const _strategies = useAppSelector(selectStrategies)
    const _policies = useAppSelector(selectPolicies)
    const _epoch = useAppSelector(selectEpoch)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _evaluationCreatedMessage: string = useAppSelector(selectEvaluationCreatedMessage)
    const [evaluationName, setEvaluationName] = useState<string>('')
    const [selectedStrategy, setSelectedStrategy] = useState<number>(-1)
    const [doNothingScenario, setDoNothingScenario] = useState<boolean>(true)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [selectedResponsePlans, setSelectedResponsePlans] = useState<number[]>([])
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [running, setRunning] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const nextDate: DateTime = DateTime.fromMillis(_epoch, { zone: _timeZone })
        setEvaluationName(
            `Evaluation ${nextDate.toFormat(_config.date_format.date)} ${nextDate.toFormat(_config.date_format.time)}`
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch, _timeZone])

    useEffect(() => {
        if (_evaluationCreatedMessage !== '') {
            setShowFeedback(true)
            dispatch(storeEvaluationCreatedMessage(''))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationCreatedMessage])

    const handleClose = () => {
        setSearchTerm('')
        setEvaluationName('')
        setSelectedStrategy(-1)
        setDoNothingScenario(true)
        setSelectedResponsePlans([])
        setShowFeedback(false)
        onClose()
        setCreateEvaluationDialogCancel(true)
    }

    const handleChecked = (event: any) => {
        const checkedRpId = Number.parseInt(event.target.value)
        const isSelected = selectedResponsePlans.find(currentRp => currentRp === checkedRpId)
        let nextSelection = selectedResponsePlans
        if (isSelected) {
            nextSelection = selectedResponsePlans.filter(rpId => rpId !== checkedRpId)
        } else {
            nextSelection.push(checkedRpId)
        }
        setSelectedResponsePlans([...nextSelection])
    }

    const handleEventChange = (event: any) => {
        setSelectedStrategy(Number(event.target.value))
        setSelectedResponsePlans([])
    }

    const handleCreateNewEvaluation = () => {
        const responsePlans = _policies
            .filter(rp => selectedResponsePlans.includes(rp.id))
            .map(rp => {
                return rp.data.eid.split('-')[1]
            })
        const doNothing = doNothingScenario ? '0' : undefined
        if (doNothing === '0') responsePlans.push(doNothing)

        const evaluation = {
            name: evaluationName,
            eventId: selectedStrategy.toString(),
            responsePlans: responsePlans,
            epoch: _epoch,
        }
        dispatch(createNewStaticEvaluationManage({ evaluation, allViewModes }))
        setRunning(true)
    }

    const handleFeedbackClose = () => {
        setShowFeedback(false)
        setRunning(false)
        onClose()
    }

    const enableRunButton = selectedResponsePlans.length > 0 && evaluationName !== '' && selectedStrategy !== -1

    return (
        <CustomDialog
            handleClose={handleClose}
            title={t('manageEvaluation.newEvaluation')}
            open={open}
            fullScreen={false}
            maxWidth={'xs'}>
            <NewManageEvaluationContentWrapper>
                <TextField
                    id='eventName'
                    value={evaluationName || ''}
                    label={t('manageEvaluation.name')}
                    variant={'standard'}
                    autoFocus={true}
                    onChange={e => setEvaluationName(e.target.value)}
                    style={{
                        width: '100%',
                        marginBottom: '20px',
                    }}
                />
                <DoNothingScenario
                    control={
                        <Switch
                            color='primary'
                            checked={doNothingScenario}
                            onChange={e => setDoNothingScenario(e.target.checked)}
                        />
                    }
                    label={t('manageEvaluation.doNothingScenario')}
                    labelPlacement={'start'}
                />
                <EventsDropDownForm key='evaluation-input-form' variant='standard'>
                    <EventsInputLabel>{t('manageEvaluation.events')}</EventsInputLabel>
                    <Select
                        id='events-new-mange-evaluation-selector'
                        value={selectedStrategy === -1 ? '' : selectedStrategy.toString()}
                        onChange={handleEventChange}>
                        <MenuItem key='evaluation-not-set' value={-1}>
                            {t('manageEvaluation.selectEvent')}
                        </MenuItem>
                        {_strategies.map(strategy => {
                            return (
                                <MenuItem key={strategy.eid} value={strategy.eid}>
                                    {strategy.data.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </EventsDropDownForm>
                <PrecodedResponsePlanTypography>
                    {t('manageEvaluation.precodedResponsePlans')}
                </PrecodedResponsePlanTypography>
                <SearchStrategiesForm>
                    <InputSearch
                        id={'searchResponsePlansField'}
                        autoFocus
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon color={'primary'} />
                            </InputAdornment>
                        }
                        placeholder={t('manageEvaluation.searchStrategies')}
                        onChange={(ev: any) => {
                            const text = ev.target as HTMLInputElement
                            setSearchTerm(text.value)
                        }}
                        disableUnderline={false}
                        value={searchTerm}
                        sx={searchTerm.length > 3 ? { width: '80%' } : { width: '100%' }}
                    />
                    {searchTerm.length > 3 && (
                        <NewManageEvaluationResetButton onClick={() => setSearchTerm('')} variant='contained'>
                            <Typography variant='button'>{t('manageEvaluation.reset')}</Typography>
                        </NewManageEvaluationResetButton>
                    )}
                </SearchStrategiesForm>

                {_policies
                    ?.filter(responsePlan => responsePlan.eid.includes(selectedStrategy) && selectedStrategy !== -1)
                    .filter(
                        responsePlan =>
                            !searchTerm ||
                            searchTerm === '' ||
                            (searchTerm.length > 1 &&
                                responsePlan.data.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    )
                    .map(responsePlan => {
                        const strategy = responsePlan.data
                        if (strategy.name !== 'Do Nothing') {
                            return (
                                <ResponsePlanFormControlLabel
                                    key={responsePlan.id}
                                    control={
                                        <Checkbox
                                            checked={selectedResponsePlans.includes(responsePlan.id)}
                                            onChange={handleChecked}
                                            value={strategy.id}
                                            color='primary'
                                        />
                                    }
                                    label={strategy.name}
                                />
                            )
                        } else {
                            return ''
                        }
                    })}
            </NewManageEvaluationContentWrapper>
            <NewManageEvaluationDialogButtonsWrapper>
                <NewManageEvaluationDialogButton onClick={handleClose} variant='text' disabled={running}>
                    <Typography variant='button' color='primary'>
                        {t('clockButtons.cancel')}
                    </Typography>
                </NewManageEvaluationDialogButton>
                <NewManageEvaluationDialogButton
                    onClick={handleCreateNewEvaluation}
                    variant='contained'
                    disabled={!enableRunButton}>
                    {running ? (
                        <PulseLoader size={8} margin={2} color='#FFF'></PulseLoader>
                    ) : (
                        <Typography variant='button'>{t('clockButtons.run')}</Typography>
                    )}
                </NewManageEvaluationDialogButton>
            </NewManageEvaluationDialogButtonsWrapper>
            {showFeedback && (
                <EventsDialogSnackbarStyled
                    open={showFeedback}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={2000}
                    onClose={handleFeedbackClose}>
                    <Alert onClose={handleFeedbackClose} severity={'info'} sx={{ width: '100%' }}>
                        {t(_evaluationCreatedMessage)}
                    </Alert>
                </EventsDialogSnackbarStyled>
            )}
        </CustomDialog>
    )
}

export default NewManageEvaluationDialog
