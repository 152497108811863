import {
    Button,
    Card,
    CardHeader,
    FormControl,
    FormControlLabel,
    Typography,
} from '@mui/material'
import { NAVY_BLUE, NO_DATA } from '../../theme'
import styled from '@emotion/styled'
import Slide from '@mui/material/Slide/Slide'

export const EventsInfoModalSlideStyled = styled(Slide)`
  margin-top: 70px;
  z-index: 1051;
`

export const EventsInfoModalLabelStyled = styled(FormControlLabel)`
  margin-left: 0;
`

export const EventsInfoModalFormControlStyled = styled(FormControl)`
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  margin-top: 12px;
`
export const EventsInfoModalDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const EventsInfoModalDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 24px;
`

export const EventsInfoModalTitle = styled(Typography)`
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  font-size: 24px;
  font-weight: bold;
  color: ${NAVY_BLUE};
`

export const EventsInfoModalTitleRange = styled(Typography)`
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  color: ${NAVY_BLUE};
  font-size: 16px;
  font-weight: lighter;
  text-transform: capitalize;
  margin-top: 12px;
`

export const CardEventsInfoModalStyled = styled(Card)`
  padding: 8px 16px;
  position: absolute;
  z-index: 99;
  min-width: 100px;
  margin: auto 0;
  right: 8px;
  top: 8px;
`
export const CardEventsHeaderInfoModalStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
  span {
    font-size: 24px;
    font-weight: bold;
  }
`
