import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const eventsLogListInitialState: IEventsLogsState = {
    fetchingEvensList: false,
    eventsList: [],
    eventsDateRange: {
        from: 0,
        to: 0
    }
}

const listEventsSlice = createSlice({
    name: 'eventsLogList',
    initialState: eventsLogListInitialState,
    reducers: {
        storeEventsList: (state, action: PayloadAction<any>): void => {
            state.eventsList = action.payload
        },
        storeEventsLisRange: (state, action: PayloadAction<any>): void => {
            state.eventsDateRange = action.payload
        },
        restoreInitialEventsLisList: (state, action: PayloadAction): void => {
            state.fetchingEvensList = false
            state.eventsList = []
        },
        setFetchingEventsList: (state, action: PayloadAction<any>): void => {
            state.fetchingEvensList = false
        },
        loadEventsLog: (state, action: PayloadAction<any>): void => {
            state.fetchingEvensList = true
        }
    },
})

export const {
    storeEventsList,
    restoreInitialEventsLisList,
    setFetchingEventsList,
    loadEventsLog,
    storeEventsLisRange
} = listEventsSlice.actions

export const selectFetchingEventList = (state: RootState) => state.eventsLogList.fetchingEvensList
export const selectEventLogList = (state: RootState) => state.eventsLogList.eventsList
export const selectEventLogRange = (state: RootState) => state.eventsLogList.eventsDateRange

export default listEventsSlice.reducer
