import { all, put } from 'redux-saga/effects'
import coreSaga from '../features/core/coreSaga'
import patternSaga from '../features/pattern/store/patternSaga'
import mapboxSaga from '../features/mapbox/mapboxSaga'
import kpisSaga from '../containers/analyzeContainer/components/kpi/store/kpisSaga'
import analyzeContainerSaga from '../containers/analyzeContainer/AnalyzeContainerSaga'
import stationSelectedSaga from '../features/stationDialog/store/stationSelectedSaga'
import realDataSaga from '../features/core/realData/realDataSaga'
import analyticalDataSaga from '../features/core/analyticalData/analyticalDataSaga'
import simulatedDataSaga from '../features/core/simulatedlData/simulatedDataSaga'
import kpisResponsePlansSaga from '../containers/manageContainer/components/kpi/store/kpisResponsePlansSaga'
import manageContainerSaga from '../containers/manageContainer/ManageContainerSaga'
import qualityManagerDialogSaga from '../features/qualityManagerDialog/store/qualityManagerDialogSaga'
import mapDataSaga from '../features/core/mapData/mapDataSaga'
import viewSettingsSaga from '../features/viewSettings/viewSettingsSaga'
import horizonSaga from '../features/horizonSlider/horizonSilderSaga'
import authSaga from '../features/auth/authSaga'
import dashboardSaga from '../containers/dashboardContainer/DashboardContainerSaga'
import riskPredictionDataSaga from '../features/core/riskPredictionData/riskPredictionDataSaga'
import evaluationsSaga from '../features/evaluationsSelector/store/evaluationsSaga'
import { setCurrentError, setStatus } from '../features/core/coreSlice'
import eventsSaga from '../features/eventsToolbar/store/eventsSaga'
import evaluationsLandUseSaga from '../features/evaluationsSelector/store/evaluationsLandUseSaga'
import baselineLandUseSaga from '../features/baselineLandUse/baselineLandUseSaga'
import evaluationSimulationDataSaga from '../features/core/simulationEvaluationData/evaluationSimulationDataSaga'
import searchFeatureSaga from '../features/search/store/searchFeatureSaga'
import precodedResponsePlansSaga
    from '../features/evaluationsSelector/newManageDemoEvaluationDialog/store/precodedResponsePlansSaga'
import evaluationsManageSaga from '../features/evaluationsSelector/store/evaluationsManageSaga'
import speedRecommendationDataSaga from '../features/core/speedRecommendationData/speedRecommendationDataSaga'
import actionsSaga from '../features/actionsToolbar/store/actionsSaga'
import responsePlanSaga from '../features/responsePlanToolbar/store/responsePlanSaga'
import responsePlanManageLiveSaga
    from '../features/evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSaga'
import evaluationLiveSaga from '../features/evaluationsToolbar/store/evaluationLiveSaga'
import trafficActionSaga from '../containers/manageContainer/components/trafficActions/store/trafficActionSaga'
import listEventsSaga from '../features/listEventsModal/store/listEventsSaga'

export default function* rootSaga() {
    try {
        yield all([
            coreSaga(),
            patternSaga(),
            kpisSaga(),
            kpisResponsePlansSaga(),
            mapboxSaga(),
            stationSelectedSaga(),
            analyzeContainerSaga(),
            manageContainerSaga(),
            realDataSaga(),
            analyticalDataSaga(),
            simulatedDataSaga(),
            riskPredictionDataSaga(),
            speedRecommendationDataSaga(),
            qualityManagerDialogSaga(),
            mapDataSaga(),
            viewSettingsSaga(),
            horizonSaga(),
            authSaga(),
            evaluationsSaga(),
            evaluationsLandUseSaga(),
            evaluationsManageSaga(),
            eventsSaga(),
            actionsSaga(),
            dashboardSaga(),
            baselineLandUseSaga(),
            evaluationSimulationDataSaga(),
            searchFeatureSaga(),
            precodedResponsePlansSaga(),
            responsePlanSaga(),
            responsePlanManageLiveSaga(),
            evaluationLiveSaga(),
            trafficActionSaga(),
            listEventsSaga(),
        ])
    } catch (error: any) {
        const mode: 'development' | 'production' | 'test' = process.env.NODE_ENV || 'production'
        if (mode === 'development') {
            console.log(error)
        }
        yield put(setStatus('failed'))
        yield put(setCurrentError({ name: error.name, code: error.code, message: error.message }))
    }
}
