import Map, { CircleLayer, FillLayer, LineLayer, Layer, MapEvent, Source } from 'react-map-gl'
import {
    selectMapboxToken,
    selectStyle,
    toggleDetectors,
    selectVisibility,
    toggleLocal,
    toggleUrban1,
    toggleSuburban,
    toggleMotorways,
    toggleReportedIncidents,
    toggleTestEvents,
    toggleDetectedIncidents,
    toggleRealEvents,
    toggleExternalIncidents,
    toggleUrban2,
    toggleActions,
    selectMapboxData,
    selectIncidents,
    selectNetwork,
    toggleCentroid,
    toggleOrthophoto,
    selectTrafficActions,
    selectEvents,
    selectTrafficActionsNetworkFlag,
} from './mapboxSlice'
import React, { useEffect, useState } from 'react'
import {
    detectedIncidentsLayerInitialState,
    detectorsLayerInitialState,
    externalIncidentsLayerInitialState,
    getColorByValue,
    getRoadTypeName,
    localLayerInitialState,
    motorwaysLayerInitialState,
    realIncidentsLayerInitialState,
    riskPredictionIncidentsLayerInitialState,
    suburbanLayerInitialState,
    testIncidentsLayerInitialState,
    urban1LayerInitialState,
    urban2LayerInitialState,
    getAnchorTooltip,
    getBorderDetectorStyles,
    getColorStyles,
    getColorByReliability,
    centroidLayerInitialState,
    centroidLayerSelectedInitialState,
    centroidClickLayerSelectedInitialState,
    lineFromConnectCentroidState,
    lineToConnectCentroidState,
    initialFromLineData,
    initialToLineData,
    getFoundLineData,
    centroidEditedInitialState,
    createNextTooltipData,
    getTooltipFeatureLandUseExtraData,
    getFeature,
    getIdToShow,
    trafficActionsLayerInitialState,
    publicTransportLayerInitialState,
    publicTransportHighlightLayerInitialState,
    realEventsLayerInitialState,
    testEventsLayerInitialState,
    paintLineLayerToOriginal,
    speedRecommendationLayerInitialState,
    subpathsLayerInitialState,
    nodesLayerInitialState,
    diversionRouteSource,
    diversionRouteLayer,
} from '../../helpers/MapboxHelper'
import {
    selectAnalyticalDataUpdates,
    selectConfig,
    selectEpoch,
    selectMainConfig,
    selectRealDataUpdates,
    selectSimulatedDataUpdates,
    selectStatus,
    selectTimeZone,
} from '../core/coreSlice'
import { Alert, AlertColor, CircularProgress, Snackbar, Typography } from '@mui/material'
import {
    FeatureTooltip,
    FeatureTooltipCentroidModified,
    HeaderText,
    IncidentIcon,
    IncidentTooltip,
    LayerControlsContainer,
    MapBoxLoader,
    MapBoxLoaderMaterial,
    MapBoxLoaderWrapper,
    MapboxWrapper,
    SubPathListHeader,
    SubPathListList,
    TooltipActionContent,
    TooltipCircle,
    TooltipCircleBackground,
    TooltipContent,
    TooltipFoundFeature,
    TooltipHeader,
    TooltipText,
} from './mapboxStyles'
import { StationDialog } from '../stationDialog/stationDialog'
import {
    loadStationSelected,
    selectOpenChartStation,
    setOpenChart,
    selectStationFeatureId,
    IStationSelectedData,
} from '../stationDialog/store/stationSelectedSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import CustomSpeedDial from '../customSpeedDial/customSpeedDial'
import Legend from '../legend/legend'
import { getAllViewMode, getAllViewModeBaseline, getViewMode } from '../../helpers/ContainerHelper'
import Search from '../search/search'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { selectViewsLayout } from '../viewSettings/viewSettingsSlice'
import Zoom from '../zoom/zoom'
import { RootState } from '../../app/store'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { CentroidModifiedInfo } from '../centroidModifiedInfo/centroidModifiedInfo'
import { CentroidInfoModal } from '../centroidModal/centroidInfoModal'
import {
    selectEventsSelected,
    setOpenEventsDialog,
    storeFeatureEventsSelected,
} from '../eventsToolbar/store/eventsSlice'
import { selectSelectedEvaluation } from '../evaluationsSelector/store/evaluationsSlice'
import { selectMapHorizonSelected } from '../mapHorizonSelector/store/mapHorizonSelectedSlice'
import { useTranslation } from 'react-i18next'
import { selectEvaluationLandUseSimulationData } from '../core/simulationEvaluationData/evaluationSimulationDataSlice'
import { selectBaselineData } from '../baselineLandUse/baselineLandUseSlice'
import { NAVY_BLUE, SUBPATH_NODES, WHITE } from '../../theme'
import { loadSearchFeature, selectFoundFeatureList } from '../search/store/searchFeatureSlice'
import { PtLinesModal } from '../ptLinesModal/ptLinesModal'
import { DateTime } from 'luxon'
import {
    convertUnitsFromMetricToImperial,
    converterToImperialSystem,
    getUnitTypeFromUnit,
} from '../../helpers/unitsHelper'
import { UNITS_BY_SYSTEM, UNIT_TYPE } from '../../constants/unitsConstants'
import { getIncidentIcon, getSpeedRecommendationIcon, loadMapboxImages } from './mapboxLoadImagesAndIcons'
import TrafficActionsRenderer from '../../containers/manageContainer/components/trafficActions/trafficActionsRenderer'
import { selectActionIsSelected } from '../actionsToolbar/store/actionsSlice'

const MapboxComponent: React.FC<IMapboxComponentProps> = ({
    mapId,
    viewport,
    networkLayers,
    actionLayers,
    incidentLayers,
    setSelected,
    containerName,
    children,
    showLegend,
    editCentroid,
    changeViewMode,
    isBaseline,
    optionLandUseView,
    optionManageView,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const _mapboxStyle: string = useAppSelector(selectStyle)
    const _mapboxToken: string = useAppSelector(selectMapboxToken)
    const _network: INetwork = useAppSelector(selectNetwork)
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _mainConfig: IMainConfig = useAppSelector(selectMainConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _visibility: IMapContainerLayers = useAppSelector(selectVisibility)
    const _epoch = useAppSelector(selectEpoch)
    const _mapboxData = useAppSelector((state: RootState) => selectMapboxData(state, mapId))
    const _views_layout: number = useAppSelector(selectViewsLayout)
    const _incidents = useAppSelector(selectIncidents)
    const _events = useAppSelector(selectEvents)
    const _trafficActions = useAppSelector(selectTrafficActions)
    const _realDataUpdates: boolean = useAppSelector(selectRealDataUpdates)
    const _analyticalDataUpdates: boolean = useAppSelector(selectAnalyticalDataUpdates)
    const _simulatedDataUpdates: boolean = useAppSelector(selectSimulatedDataUpdates)
    const _showTrafficActionsNetwork: boolean = useAppSelector(selectTrafficActionsNetworkFlag)
    const _openChart: boolean = useAppSelector((state: RootState) => selectOpenChartStation(state, mapId))
    const _stationFeatureIdSelected: IStationSelectedData = useAppSelector((state: RootState) =>
        selectStationFeatureId(state, mapId)
    )
    const _foundFeatureList: IFoundFeature[] = useAppSelector((state: RootState) =>
        selectFoundFeatureList(state, mapId)
    )
    const _selectedEvaluation: IEvaluationStatic = useAppSelector(selectSelectedEvaluation)
    const _eventsIsSelected: boolean = useAppSelector(selectEventsSelected)
    const _appStatus: string = useAppSelector(selectStatus)
    const _mapHorizonSelected: IMapHorizon = useAppSelector(selectMapHorizonSelected)
    const _evaluationSimulationData: { [key: string]: IEvaluationSimulationByHorizonData[] } = useAppSelector(
        selectEvaluationLandUseSimulationData
    )
    const _selectBaselineData: IBaselineLandUseTypes[] = useAppSelector(selectBaselineData)
    const _actionsIsSelected: boolean = useAppSelector(selectActionIsSelected)
    const [detectorsLayer, setDetectorsLayer] = useState<CircleLayer>(detectorsLayerInitialState)
    const [centroidLayer, setCentroidLayer] = useState<FillLayer>(centroidLayerInitialState)
    const [centroidLayerSelected, setCentroidLayerSelected] = useState<FillLayer>(centroidLayerSelectedInitialState)
    const [centroidClickLayerSelected, setCentroidClickLayerSelected] = useState<FillLayer>(
        centroidClickLayerSelectedInitialState
    )
    const [centroidEdited, setCentroidEdited] = useState<FillLayer>(centroidEditedInitialState)
    const [lineFromConnectData, setLineFromConnectData] = useState(initialFromLineData)
    const [lineToConnectData, setLineToConnectData] = useState(initialToLineData)
    const [motorwaysLayer, setMotorwaysLayer] = useState<LineLayer>(motorwaysLayerInitialState)
    const [suburbanLayer, setSuburbanLayer] = useState<LineLayer>(suburbanLayerInitialState)
    const [urban1Layer, setUrban1Layer] = useState<LineLayer>(urban1LayerInitialState)
    const [urban2Layer, setUrban2Layer] = useState<LineLayer>(urban2LayerInitialState)
    const [localLayer, setLocalLayer] = useState<LineLayer>(localLayerInitialState)
    const [openSubPathInfoModal, setOpenSubPathInfoModal] = useState<boolean>(false)
    const [subPathNodeInfoModal, setSubPathNodeInfoModal] = useState<any>('')

    const [publicTransportLayer, setPublicTransportLayer] = useState<LineLayer>(publicTransportLayerInitialState)
    const [publicTransportHighlightLayer, setPublicTransportHighlightLayer] = useState<LineLayer>(
        publicTransportHighlightLayerInitialState
    )
    const [showFeatureTooltip, setShowFeatureTooltip] = useState<boolean>(false)
    const [featureTooltipPosition, setFeatureTooltipPosition] = useState<IPosition>({ longitude: 0, latitude: 0 })
    const [featureTooltipData, setFeatureTooltipData] = useState<ITooltipData | null>(null)
    const [showCentroidTooltip, setShowCentroidTooltip] = useState<boolean>(false)
    const [centroidTooltipPosition, setCentroidTooltipPosition] = useState<IPosition>({ longitude: 0, latitude: 0 })
    const [centroidTooltipData, setCentroidTooltipData] = useState<ICentroidTooltipData | null>(null)
    const [centroidClickSelectedData, setCentroidClickSelectedData] = useState<ICentroidTooltipData | null>(null)
    const [centroidEditedTooltipData, setCentroidEditedTooltipData] = useState<ICentroidEditedTooltipData | null>(null)
    const [showCentroidEditedTooltip, setShowCentroidEditedTooltip] = useState<boolean>(false)
    const [centroidEditedTooltipPosition, setCentroidEditedTooltipPosition] = useState<IPosition>({
        longitude: 0,
        latitude: 0,
    })
    const [openCentroidInfoModal, setOpenCentroidInfoModal] = useState<boolean>(false)
    const [showIncidentTooltip, setShowIncidentTooltip] = useState<boolean>(false)
    const [incidentTooltipPosition, setIncidentTooltipPosition] = useState<IPosition>({ longitude: 0, latitude: 0 })
    const [incidentTooltipData, setIncidentTooltipData] = useState<IIncidentTooltipData | null>(null)
    const [showEventTooltip, setShowEventTooltip] = useState<boolean>(false)
    const [eventTooltipPosition, setEventTooltipPosition] = useState<IPosition>({ longitude: 0, latitude: 0 })
    const [eventTooltipData, setEventTooltipData] = useState<IEventTooltipData | null>(null)
    const [actionsTooltipData, setActionsTooltipData] = useState<IActionsTooltipData | null>(null)
    const [showActionsTooltip, setShowActionsTooltip] = useState<boolean>(false)
    const [actionsTooltipPosition, setActionsTooltipPosition] = useState<IPosition>({
        longitude: 0,
        latitude: 0,
    })
    const [riskPredictionTooltipData, setRiskPredictionTooltipData] = useState<IRiskPredictionTooltipData | null>(null)
    const [showRiskPredictionTooltip, setShowRiskPredictionTooltip] = useState<boolean>(false)
    const [riskPredictionTooltipPosition, setRiskPredictionTooltipPosition] = useState<IPosition>({
        longitude: 0,
        latitude: 0,
    })
    const [speedRecommendationTooltipData, setSpeedRecommendationTooltipData] =
        useState<ISpeedRecommendationTooltipData | null>(null)
    const [showSpeedRecommendationTooltip, setShowSpeedRecommendationTooltip] = useState<boolean>(false)
    const [speedRecommendationTooltipPosition, setSpeedRecommendationTooltipPosition] = useState<IPosition>({
        longitude: 0,
        latitude: 0,
    })
    const [foundFeaTureTooltipData, setFoundFeaTureTooltipData] = useState<IFoundFeatureTooltipData | null>(null)
    const [showFoundFeaTureTooltip, setShowFoundFeaTureTooltip] = useState<boolean>(false)
    const [foundFeaTureTooltipPosition, setFoundFeaTureTooltipPosition] = useState<IPosition>({
        longitude: 0,
        latitude: 0,
    })
    const [mapbox, setMapbox] = useState<any>(null)
    const [searchFeature, setSearchFeature] = useState<any>(null)
    const [foundFeature, setFoundFeature] = useState<any>(undefined)
    const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
    const [feedbackMessage, setFeedbackMessage] = useState<string>('')
    const [feedbackSeverity, setFeedbackSeverity] = useState<AlertColor>('warning')
    const [amAttracted, setAmAttracted] = useState(null)
    const [amGenerated, setAmGenerated] = useState(null)
    const [pmAttracted, setPmAttracted] = useState(null)
    const [pmGenerated, setPmGenerated] = useState(null)
    const [ptLinesSelectedToHighlight, setPtLinesSelectedToHighlight] = useState<any[]>([])
    const [ptLinesSelected, setPtLinesSelected] = useState<any[]>([])

    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)
    const allViewModesBaseline = getAllViewModeBaseline(
        containerName,
        _moduleConfig.baseline_view_mode,
        _moduleConfig.modules
    )
    const viewMode = getViewMode(_mapboxData.viewModeId, isBaseline ? allViewModesBaseline : allViewModes)
    const { sectionOnClick, units, date_format } = _moduleConfig

    useEffect(() => {
        if (mapbox && _selectedEvaluation) {
            handleCentroidModalInfoClose()
            if (_selectedEvaluation.centroid_id) {
                const features: Feature<Geometry, GeoJsonProperties>[] = mapbox.querySourceFeatures(
                    'aimsunLive_centroid_source',
                    {
                        sourceLayer: 'geogkcentroid',
                    }
                )
                const found = features.find((feature: any) => {
                    const featureId: string = feature.properties?.id || 'unknown'
                    return _selectedEvaluation.centroid_id?.toString() === featureId.toString()
                })

                if (found && found.properties) {
                    const attr = JSON.parse(found.properties.attributes)
                    const longitude = attr.position[0]
                    const latitude = attr.position[1]
                    const zoom = 12

                    if (!isBaseline) {
                        mapbox.setFilter('aimsunLive_centroid_edited', ['in', 'id', found.id])
                        const nextTooltipData: ICentroidEditedTooltipData = createNextTooltipData(
                            found,
                            attr,
                            _selectedEvaluation
                        )
                        setCentroidEditedTooltipData({ ...nextTooltipData })
                        setShowCentroidEditedTooltip(true)
                        setCentroidEditedTooltipPosition({ longitude, latitude })
                    } else {
                        hideCentroidEditedInfo()
                    }
                    setTimeout(() => {
                        mapbox.flyTo({ center: [longitude, latitude], zoom, duration: 1000, essential: true })
                    }, 250)
                }
            } else {
                hideCentroidEditedInfo()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation, mapbox, isBaseline])

    const hideCentroidEditedInfo = () => {
        const centroidEditedLayer = mapbox.getLayer('aimsunLive_centroid_edited')
        if (centroidEditedLayer) {
            mapbox.setFilter('aimsunLive_centroid_edited', ['in', 'id', ''])
            setCentroidEditedTooltipData(null)
            setShowCentroidEditedTooltip(false)
            setCentroidEditedTooltipPosition({ longitude: 0, latitude: 0 })
        }
    }

    useEffect(() => {
        if (mapbox) {
            if (foundFeature && foundFeature.id !== searchFeature?.id) {
                setSearchFeature(foundFeature)
                goToFeature(foundFeature)
            }
            updateLayersVisibility(_visibility[mapId])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapbox])

    useEffect(() => {
        if (!_actionsIsSelected) {
            setOpenSubPathInfoModal(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_actionsIsSelected])

    useEffect(() => {
        if (mapbox) {
            if (Object.keys(_evaluationSimulationData).length > 0) {
                addMapListeners()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amAttracted])

    useEffect(() => {
        if (mapbox) {
            if (foundFeature && foundFeature.id !== searchFeature?.id) {
                setSearchFeature(foundFeature)
                goToFeature(foundFeature)
            }
            updateLayersVisibility(_visibility[mapId])
        }
        setAmGenerated(
            _evaluationSimulationData[viewMode.variable]
                ? // @ts-ignore
                  _evaluationSimulationData[viewMode.variable][0]['am_generated'][0][0]?.value
                : 0
        )
        setPmGenerated(
            _evaluationSimulationData[viewMode.variable]
                ? // @ts-ignore
                  _evaluationSimulationData[viewMode.variable][0]['pm_generated'][0][0]?.value
                : 0
        )
        setAmAttracted(
            _evaluationSimulationData[viewMode.variable]
                ? // @ts-ignore
                  _evaluationSimulationData[viewMode.variable][0]['am_attracted'][0][0]?.value
                : 0
        )
        setPmAttracted(
            _evaluationSimulationData[viewMode.variable]
                ? // @ts-ignore
                  _evaluationSimulationData[viewMode.variable][0]['pm_attracted'][0][0]?.value
                : 0
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationSimulationData])

    useEffect(() => {
        if (mapbox) {
            handleCentroidModalInfoClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_mapHorizonSelected])

    useEffect(() => {
        if (mapbox) {
            if (_eventsIsSelected) {
                const newPaint: any = paintLineLayerToOriginal()
                setMotorwaysLayer({ ...motorwaysLayerInitialState, paint: newPaint })
                setSuburbanLayer({ ...suburbanLayerInitialState, paint: newPaint })
                setUrban1Layer({ ...urban1LayerInitialState, paint: newPaint })
                setUrban2Layer({ ...urban2LayerInitialState, paint: newPaint })
                setLocalLayer({ ...localLayerInitialState, paint: newPaint })
                setPublicTransportLayer({ ...publicTransportLayerInitialState, paint: newPaint })
                setPublicTransportHighlightLayer({ ...publicTransportHighlightLayerInitialState, paint: newPaint })
            } else {
                refreshLayers()
            }
            addMapListeners()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_eventsIsSelected])

    useEffect(() => {
        if (_mapboxData !== undefined && mapbox) {
            updateLayersVisibility(_visibility[mapId])
            refreshAllAvailableFeatures()
            refreshLayers()
            addMapListeners()
            if (showFeatureTooltip) {
                if (featureTooltipData) {
                    const featureState = mapbox.getFeatureState({
                        source: featureTooltipData.source,
                        sourceLayer: featureTooltipData.sourceLayer,
                        id: featureTooltipData.id,
                    })

                    const nexTooltipData: ITooltipData = {
                        ...featureTooltipData,
                        value: featureState[viewMode.variable],
                        reliability: featureState['reliability'],
                        valueColor: getColorByValue(viewMode, featureState[viewMode.variable]),
                        reliabilityColor: getColorByReliability(viewMode, featureState['reliability']),
                    }

                    setFeatureTooltipData(nexTooltipData)
                }
            }
            if (_mapboxData.rpActions) {
                refreshTrafficActions(_mapboxData.rpActions)
                refreshIncidents(_mapboxData.rpActions, 'real')
            }
            if (_showTrafficActionsNetwork) {
                highlighSubpaths()
                highlightNodes()
            } else {
                hideSignalizedNodes()
            }
            refreshSpeedRecommendation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_mapboxData, mapbox, _eventsIsSelected, _showTrafficActionsNetwork])

    useEffect(() => {
        if (mapbox && _trafficActions.features && _selectedEvaluation.id < 0) {
            refreshTrafficActions(_trafficActions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapbox, _trafficActions, _selectedEvaluation])

    useEffect(() => {
        if (mapbox && _incidents) {
            for (const [key, value] of Object.entries(_incidents)) {
                refreshIncidents(value, key)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapbox, _incidents])

    useEffect(() => {
        if (mapbox && _events) {
            for (const [key, value] of Object.entries(_events)) {
                refreshEvents(value, key)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapbox, _events])

    useEffect(() => {
        if (mapbox) {
            updateLayersVisibility(_visibility[mapId])
            addMapListeners()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_visibility])

    useEffect(() => {
        if (mapbox) {
            mapbox.resize()
            const bounds = mapbox.getBounds()
            mapbox.fitBounds(bounds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewport])

    useEffect(() => {
        if (_simulatedDataUpdates) {
            setFeedbackOpen(true)
            setFeedbackMessage(t('feedBackMessage.simulatedReady'))
            setFeedbackSeverity('info')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_simulatedDataUpdates])

    useEffect(() => {
        if (_analyticalDataUpdates) {
            setFeedbackOpen(true)
            setFeedbackMessage(t('feedBackMessage.analyticalReady'))
            setFeedbackSeverity('info')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_analyticalDataUpdates])

    useEffect(() => {
        if (_realDataUpdates) {
            setFeedbackOpen(true)
            setFeedbackMessage(t('feedBackMessage.realUpdateReceived'))
            setFeedbackSeverity('info')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_realDataUpdates])

    useEffect(() => {
        if (mapbox && ptLinesSelected) {
            const ptLayer = mapbox.getLayer(`aimsunLive_public_transport`)
            if (ptLayer) {
                const ptLinesIds = ptLinesSelected.map(ptLine => getHighlightOrClearPtLine(ptLine))
                const uniqueIdsTemp = new Set(ptLinesIds.flat())
                const uniqueIds = [...uniqueIdsTemp]

                mapbox.setFilter('aimsunLive_public_transport', [
                    'match',
                    ['get', 'id'],
                    uniqueIds.length > 0 ? uniqueIds : '',
                    true,
                    false,
                ])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ptLinesSelected])

    useEffect(() => {
        if (mapbox && ptLinesSelectedToHighlight) {
            const ptLayer = mapbox.getLayer(`aimsunLive_public_transport_highlight`)
            if (ptLayer) {
                const uniqueIdsTemp = new Set(ptLinesSelectedToHighlight)
                const uniqueIds = [...uniqueIdsTemp]
                mapbox.setFilter('aimsunLive_public_transport_highlight', [
                    'match',
                    ['get', 'id'],
                    uniqueIds.length > 0 ? uniqueIds : '',
                    true,
                    false,
                ])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ptLinesSelectedToHighlight])

    useEffect(() => {
        if (
            (viewMode.sources.stations.now !== 'real' && !_eventsIsSelected && _openChart) ||
            (sectionOnClick && viewMode.sources.sections.now !== 'real' && !_eventsIsSelected && _openChart)
        ) {
            dispatch(setOpenChart({ mapId: mapId, openChart: false }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMode])

    const onLoad = (mapboxEvent: MapEvent) => {
        const nextMap = mapboxEvent.target
        if (containerName === 'Land use') {
            nextMap.setLayoutProperty(nextMap.getLayer(`satellite`).id, 'visibility', 'visible')
            nextMap.setLayoutProperty(nextMap.getLayer(`background`).id, 'visibility', 'visible')
        }

        if (!mapbox) {
            setMapbox(nextMap)
            loadMapboxImages(nextMap)
        }
    }

    const updateLayersVisibility = (visibility: ILayerVisibility) => {
        if (mapbox && viewMode.variable === 'travel_time_pt') {
            for (const [key] of Object.entries(visibility)) {
                const layer = mapbox.getLayer(`aimsunLive_${key}`)
                if (layer) {
                    mapbox.setLayoutProperty(layer.id, 'visibility', 'none')
                }
            }
            mapbox.setLayoutProperty(mapbox.getLayer(`aimsunLive_public_transport`).id, 'visibility', 'visible')
        } else {
            const ptLayer = mapbox.getLayer(`aimsunLive_public_transport`)
            if (ptLayer) {
                mapbox.setLayoutProperty(ptLayer.id, 'visibility', 'none')
                mapbox.setFilter('aimsunLive_public_transport', ['match', ['get', 'id'], '', true, false])
                setPtLinesSelected([])
            }

            for (const [key, value] of Object.entries(visibility)) {
                const layer = mapbox.getLayer(`aimsunLive_${key}`)
                const networkToHide = ['detectors', 'motorways', 'suburban', 'urban', 'local', 'urban_1', 'urban_2']
                const status: 'none' | 'visible' = value
                    ? _selectedEvaluation.id === -2 && networkToHide.includes(key) && !_eventsIsSelected
                        ? 'none'
                        : 'visible'
                    : 'none'
                if (layer) {
                    mapbox.setLayoutProperty(layer.id, 'visibility', status)
                }
            }
        }
        const orthophotoVisibility: 'none' | 'visible' = visibility.orthophoto ? 'visible' : 'none'
        mapbox.setLayoutProperty(mapbox.getLayer(`satellite`).id, 'visibility', orthophotoVisibility)
        mapbox.setLayoutProperty(mapbox.getLayer(`background`).id, 'visibility', orthophotoVisibility)
    }

    const refreshEvents = (data: any, type: string) => {
        const source: any = mapbox.getSource(type)
        // @ts-ignore
        const visibility: boolean = _visibility[mapId][type]

        if (source) {
            if (data && data.features) {
                source.setData(data)
            }
        } else {
            const layerId: string = `aimsunLive_events_${type}`

            mapbox.removeLayer(`aimsunLive_events_${type}`)
            mapbox
                .addLayer({
                    id: layerId,
                    type: 'symbol',
                    source: `aimsunLive_events_${type}_source`,
                    layout: {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': [
                            'case',
                            ['==', ['get', 'in_simulation'], true],
                            ['concat', type, '_active'],
                            type,
                        ],
                        visibility: visibility ? 'visible' : 'none',
                    },
                })
                .on('mousemove', layerId, (event: any) => {
                    renderEventTooltip(event)
                })
                .on('mouseout', layerId, () => {
                    setShowEventTooltip(false)
                })
        }
    }

    const refreshIncidents = (data: any, type: string) => {
        const source: any = mapbox.getSource(type)
        // @ts-ignore
        const visibility: boolean = _visibility[mapId][type]

        if (source) {
            if (data && data.features) {
                source.setData(data)
            }
        } else {
            const layerId: string = `aimsunLive_${type}`

            mapbox.removeLayer(`aimsunLive_${type}`)
            mapbox
                .addLayer({
                    id: layerId,
                    type: 'symbol',
                    source: `aimsunLive_${type}_source`,
                    layout: {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': [
                            'case',
                            ['==', ['get', 'in_simulation'], true],
                            ['concat', type, '_active'],
                            type,
                        ],
                        visibility: visibility ? 'visible' : 'none',
                    },
                })
                .on('mousemove', layerId, (event: any) => {
                    renderIncidentTooltip(event, type)
                })
                .on('mouseout', layerId, () => {
                    setShowIncidentTooltip(false)
                })
        }
    }

    const refreshTrafficActions = (trafficActions: FeatureCollection<Geometry, GeoJsonProperties>) => {
        const source: any = mapbox.getSource('aimsunLive_traffic_actions_source')
        if (source) {
            if (trafficActions.features) {
                const nodesLayer = mapbox.getLayer('aimsunLive_nodes')
                const layerId: string = `aimsunLive_traffic_actions`
                mapbox.removeLayer(layerId)
                mapbox.addLayer(
                    {
                        id: layerId,
                        type: 'symbol',
                        source: `aimsunLive_traffic_actions_source`,
                        layout: {
                            'icon-allow-overlap': true,
                            'icon-ignore-placement': true,
                            'icon-image': [
                                'case',
                                ['==', ['get', 'in_simulation'], true],
                                ['concat', 'traffic_actions', '_active'],
                                'traffic_actions_active',
                            ],
                            visibility: _visibility[mapId].traffic_actions ? 'visible' : 'none',
                        },
                    },
                    nodesLayer ? 'aimsunLive_nodes' : ''
                )
                source.setData(trafficActions)
            }
        }
    }

    const refreshRiskPrediction = () => {
        const source: any = mapbox.getSource('aimsunLive_risk_prediction_source')
        if (source) {
            if (_mapboxData.riskPrediction && _mapboxData.riskPrediction.features) {
                const layerId: string = `aimsunLive_risk_prediction`

                mapbox.removeLayer(`aimsunLive_risk_prediction`)
                mapbox.addLayer({
                    id: layerId,
                    type: 'symbol',
                    source: `aimsunLive_risk_prediction_source`,
                    layout: {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': [
                            'case',
                            ['>=', ['get', 'risk'], Number.parseFloat(_mainConfig.defaults['red-risk-threshold'])],
                            ['concat', 'risk_prediction', '_100'],
                            ['>=', ['get', 'risk'], Number.parseFloat(_mainConfig.defaults['orange-risk-threshold'])],
                            ['concat', 'risk_prediction', '_90'],
                            ['>=', ['get', 'risk'], Number.parseFloat(_mainConfig.defaults['yellow-risk-threshold'])],
                            ['concat', 'risk_prediction', '_80'],
                            'risk_prediction',
                        ],
                        visibility: 'visible',
                    },
                })

                source.setData(_mapboxData.riskPrediction)
            }
        }
    }

    const refreshSpeedRecommendation = () => {
        const source: any = mapbox.getSource('aimsunLive_speed_recommendation_source')

        if (source) {
            if (_mapboxData.speedRecommendation && _mapboxData.speedRecommendation.features) {
                const layerId: string = `aimsunLive_speed_recommendation`

                //TODO agregar los iconos correspondientes a la speed recommendation en millas
                mapbox.removeLayer(`aimsunLive_speed_recommendation`)
                mapbox.addLayer({
                    id: layerId,
                    type: 'symbol',
                    source: `aimsunLive_speed_recommendation_source`,
                    layout: {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-image': [
                            'case',
                            ['>=', ['get', 'speed'], 100],
                            ['concat', 'speed_recommendation', '_100'],
                            ['>=', ['get', 'speed'], 90],
                            ['concat', 'speed_recommendation', '_90'],
                            ['>=', ['get', 'speed'], 80],
                            ['concat', 'speed_recommendation', '_80'],
                            ['>=', ['get', 'speed'], 70],
                            ['concat', 'speed_recommendation', '_70'],
                            ['>=', ['get', 'speed'], 60],
                            ['concat', 'speed_recommendation', '_60'],
                            'speed_recommendation_60',
                        ],
                        visibility: 'visible',
                    },
                })
                source.setData(_mapboxData.speedRecommendation)
            }
        }
    }

    const goToFeature = (feature: any) => {
        const attr = JSON.parse(feature.properties.attributes)
        const longitudeCentroid =
            feature.properties.type === 'GKCentroid' ? attr.position[0] : feature.geometry.coordinates[0][0]
        const longitude = feature.geometry.type === 'Point' ? feature.geometry.coordinates[0] : longitudeCentroid

        const latitudeCentroid =
            feature.properties.type === 'GKCentroid' ? attr.position[1] : feature.geometry.coordinates[0][1]
        const latitude = feature.geometry.type === 'Point' ? feature.geometry.coordinates[1] : latitudeCentroid

        const zoom = feature.properties.type === 'GKCentroid' ? 14 : 18

        const idToShow = getIdToShow(
            _moduleConfig.searchIdentifier,
            sectionOnClick,
            feature.properties.id,
            feature.properties.eid
        )

        mapbox.flyTo({ center: [longitude, latitude], zoom })
        const nextTooltipData: IFoundFeatureTooltipData = {
            id: feature.properties.id,
            eid: feature.properties.eid,
            idToShow,
            featureType: feature.properties.type,
            anchor: 'top',
        }

        setFoundFeaTureTooltipData({ ...nextTooltipData })
        setFoundFeaTureTooltipPosition({ longitude, latitude })
        setShowFoundFeaTureTooltip(true)
    }

    const handleHover = () => {
        setSelected(mapId)
    }

    const renderIncidentTooltip = (event: any, type = '') => {
        const { lngLat, features, point } = event
        setIncidentTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        if (currentFeature) {
            const rawDescription = currentFeature.properties?.description.split('<br>')
            const timespan = rawDescription[2].split(',')
            const nextIncidentData: IIncidentTooltipData = {
                source: type,
                type: rawDescription[1],
                start_time: timespan[0],
                end_time: timespan[1],
                impact: rawDescription[3],
                in_simulation: rawDescription[4],
                anchor: getAnchorTooltip(point, viewport),
            }

            setIncidentTooltipData(nextIncidentData)
            setShowIncidentTooltip(true)
        }
    }

    const renderEventTooltip = (event: any) => {
        const { lngLat, features, point } = event
        setEventTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        if (currentFeature) {
            const properties = currentFeature.properties
            const lanes = JSON.parse(properties.lanes)
            let speeds: string[] = []

            for (const lane of lanes) {
                if (lane.open) {
                    speeds.push(
                        Number.parseFloat(
                            units === 'metric'
                                ? Number(lane.speed).toFixed(0)
                                : Number(converterToImperialSystem(UNIT_TYPE.speed, Number(lane.speed))).toFixed(0)
                        ).toString()
                    )
                } else {
                    speeds.push('-')
                }
            }
            const startTime = DateTime.fromMillis(properties.start_time, { zone: _timeZone })
            const endTime = DateTime.fromMillis(properties.end_time, { zone: _timeZone })
            const nextEventData: IEventTooltipData = {
                title: properties.event_type ? `${properties.name} (${properties.event_type})` : properties.name,
                type: properties.event_type,
                sectionId: properties.section_id,
                start_time: `${startTime.toFormat(date_format.date)} | ${startTime.toFormat(date_format.time)}`,
                end_time: `${endTime.toFormat(date_format.date)} | ${endTime.toFormat(date_format.time)}`,
                speeds: speeds,
                closeLanes: properties.closed_lanes.length > 2 ? properties.closed_lanes : 'None',
                anchor: getAnchorTooltip(point, viewport),
            }
            setEventTooltipData(nextEventData)
            setShowEventTooltip(true)
        }
    }

    const renderActionsTooltip = (event: any) => {
        const { lngLat, features, point } = event
        setActionsTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        if (currentFeature) {
            let nextActionsData
            if (
                currentFeature.properties?.actions_type === 'change_control_plan' ||
                currentFeature.properties?.actions_type === 'set_a_diversion_route'
            ) {
                const data = JSON.parse(currentFeature.properties?.data)
                let subpath: any[] = []
                if (data.subpaths) {
                    for (const [key, value] of Object.entries(data.subpaths)) {
                        subpath.push({
                            subpathId: key,
                            subpathPercentaje: value,
                        })
                    }
                }

                nextActionsData = {
                    title: currentFeature.properties?.name,
                    section_id: currentFeature.properties?.section_id,
                    subpaths: subpath,
                    node_id: data.node_id,
                    control_plan_id: data.control_plan_id,
                    anchor: getAnchorTooltip(point, viewport),
                }
            } else {
                let rawDescription: string[] = currentFeature.properties?.description
                    ? currentFeature.properties?.description
                          .replace(', Estimated End Time', '<br>Estimated End Time')
                          .split('<br>')
                    : ''
                if (units === 'imperial') {
                    const hasMetricUnit = rawDescription.filter((desc: string) => desc.includes('kmh'))
                    if (hasMetricUnit.length) {
                        const metricValue = hasMetricUnit[0].split(': ')[1]
                        const value = Number(metricValue.split(' ')[0])
                        const imperialValue = `${converterToImperialSystem('speed', value)} ${
                            UNITS_BY_SYSTEM.imperial.speed
                        }`
                        rawDescription = rawDescription.map(line => line.replace(metricValue, imperialValue))
                    }
                }
                nextActionsData = {
                    title: currentFeature.properties?.id ? currentFeature.properties.id : 'Action',
                    description: rawDescription,
                    anchor: getAnchorTooltip(point, viewport),
                }
            }
            setActionsTooltipData(nextActionsData)
            setShowActionsTooltip(true)
        }
    }

    const renderRiskPredictionTooltip = (event: any, type = '') => {
        const { lngLat, features, point } = event
        setRiskPredictionTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        if (currentFeature) {
            const rawDescription = currentFeature.properties?.description
            const nextRiskPredictionData: IRiskPredictionTooltipData = {
                source: type,
                title: 'Risk Detected',
                description: rawDescription,
                anchor: getAnchorTooltip(point, viewport),
            }

            setRiskPredictionTooltipData(nextRiskPredictionData)
            setShowRiskPredictionTooltip(true)
        }
    }

    const renderSpeedRecommendationTooltip = (event: any, type = '') => {
        const { lngLat, features, point } = event
        setSpeedRecommendationTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        if (currentFeature) {
            const value = currentFeature.properties?.speed - (currentFeature.properties?.speed % 10)
            const unit = currentFeature.properties?.units
            const motivation = currentFeature.properties?.motivation

            const nextSpeedRecommendationData: ISpeedRecommendationTooltipData = {
                source: type,
                title: t('mapbox.speedRecommendation'),
                value,
                unit,
                motivation,
                anchor: getAnchorTooltip(point, viewport),
            }

            setSpeedRecommendationTooltipData(nextSpeedRecommendationData)
            setShowSpeedRecommendationTooltip(true)
        }
    }

    const renderFeatureTooltip = (event: any) => {
        const { lngLat, features, point } = event
        setFeatureTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        const currentFeature = features[0]
        const { source, sourceLayer } = currentFeature
        const { id, eid, attributes, type } = currentFeature.properties
        const value = currentFeature.state[viewMode.variable]
        //NOTE: Landuse baselines doesn't have reliability values, but they want default 1
        const reliability = currentFeature.state.reliability === undefined ? 1 : currentFeature.state.reliability
        const attr = JSON.parse(attributes)
        const roundReliability: string = reliability?.toFixed(2)
        const valueColor = getColorByValue(viewMode, value)
        const reliabilityColor = getColorByReliability(viewMode, reliability)
        const idToShow = getIdToShow(_moduleConfig.searchIdentifier, sectionOnClick, id, eid)

        let nextTooltipData: ITooltipData = {
            id,
            eid,
            idToShow,
            value,
            source,
            sourceLayer,
            valueColor,
            reliabilityColor,
            reliability: roundReliability,
            featureType: type,
            capacity: attr['GKDetector::capacityAtt'] | attr['GKSection::capacityAtt'],
            speedLimit: attr['GKDetector::speedAtt'] | attr['GKSection::speedAtt'],
            roadType: getRoadTypeName(attr['road_type'], _moduleConfig.speed_dials_names),
            anchor: getAnchorTooltip(point, viewport),
        }
        if (containerName === 'Land use') {
            const landUseExtraData = getTooltipFeatureLandUseExtraData(
                isBaseline,
                optionLandUseView,
                id,
                allViewModes,
                allViewModesBaseline,
                viewMode,
                _evaluationSimulationData,
                _selectBaselineData,
                _selectedEvaluation
            )

            nextTooltipData = { ...nextTooltipData, landUseExtraData }
        }

        setFeatureTooltipData({ ...nextTooltipData })
        setShowFeatureTooltip(true)
        setShowCentroidTooltip(false)
    }

    const getCentroidData = (event: any): ICentroidTooltipData => {
        const { features, point } = event
        const currentFeature = features[0]
        const { id, eid, source, type, attributes } = currentFeature.properties
        const attr = JSON.parse(attributes)

        const data = {
            residential: attr.residential,
            retail: attr.retail,
            industrial: attr.industrial,
            office: attr.office,
            school: attr.school,
            medical: attr.medical,
            hotelrooms: attr.hotelrooms,
            am_generation: attr.am_baseline_generation,
            am_attraction: attr.am_baseline_attraction,
            pm_generation: attr.pm_baseline_generation,
            pm_attraction: attr.pm_baseline_attraction,
            from_sections: attr.from_sections,
            to_sections: attr.to_sections,
        }
        if (id === _selectedEvaluation.centroid_id) {
            if (optionLandUseView === 'am' || optionLandUseView === 'pm') {
                const newAmAttracted = amAttracted ? amAttracted : _selectedEvaluation.fields?.am_attraction ?? 0
                const newAmGenerated = amGenerated ? amGenerated : _selectedEvaluation.fields?.am_generation ?? 0
                const newPmAttracted = pmAttracted ? pmAttracted : _selectedEvaluation.fields?.pm_attraction ?? 0
                const newPmGenerated = pmGenerated ? pmGenerated : _selectedEvaluation.fields?.pm_generation ?? 0

                data.residential = Math.ceil(attr.residential) + Math.ceil(_selectedEvaluation.fields?.residential ?? 0)
                data.retail = Math.ceil(attr.retail) + (_selectedEvaluation.fields?.office ?? 0)
                data.industrial = Math.ceil(attr.industrial) + Math.ceil(_selectedEvaluation.fields?.industrial ?? 0)
                data.office = Math.ceil(attr.office) + Math.ceil(_selectedEvaluation.fields?.office ?? 0)
                data.school = Math.ceil(attr.school) + Math.ceil(_selectedEvaluation.fields?.school ?? 0)
                data.medical = Math.ceil(attr.medical) + Math.ceil(_selectedEvaluation.fields?.hospital ?? 0)
                data.hotelrooms = Math.ceil(attr.hotelrooms) + Math.ceil(_selectedEvaluation.fields?.hotel_rooms ?? 0)
                data.am_generation = Math.ceil(attr.am_baseline_generation) + Math.ceil(newAmGenerated)
                data.am_attraction = Math.ceil(attr.am_baseline_attraction) + Math.ceil(newAmAttracted)
                data.pm_generation = Math.ceil(attr.pm_baseline_generation) + Math.ceil(newPmGenerated)
                data.pm_attraction = Math.ceil(attr.pm_baseline_attraction) + Math.ceil(newPmAttracted)
                data.from_sections = attr.from_sections
                data.to_sections = attr.to_sections
            }
        }

        return {
            id,
            eid,
            data,
            source,
            featureType: type,
            anchor: getAnchorTooltip(point, viewport),
        }
    }

    const renderCentroidTooltip = (event: any) => {
        const { lngLat, features } = event
        mapbox.setFilter('aimsunLive_centroid_selected', ['in', 'id', features[0].properties.id])

        setCentroidTooltipPosition({ longitude: lngLat.lng, latitude: lngLat.lat })
        setCentroidTooltipData(getCentroidData(event))
        setShowCentroidTooltip(true)
    }

    const handleChartClose = () => {
        dispatch(setOpenChart({ mapId: mapId, openChart: false }))
    }

    const handleOpenCentroidInfo = (event: any) => {
        const { features } = event

        setCentroidClickSelectedData(getCentroidData(event))
        mapbox.setFilter('aimsunLive_centroid_click_selected', ['in', 'id', features[0].properties.id])
        setOpenCentroidInfoModal(true)

        const attr = JSON.parse(features[0].properties.attributes)
        const centroidPoint = [attr.position[0], attr.position[1]]

        const foundFromData = getFoundLineData(attr.from_sections, { ...lineFromConnectData }, centroidPoint)
        const foundToData = getFoundLineData(attr.to_sections, { ...lineToConnectData }, centroidPoint)

        mapbox.getSource('aimsunLive_centroid_line_from_connect_source').setData(foundFromData)
        mapbox.getSource('aimsunLive_centroid_line_to_connect_source').setData(foundToData)

        setLineFromConnectData(foundFromData)
        setLineToConnectData(foundToData)
    }

    const handleCreateActionPanel = (event: any) => {
        setOpenSubPathInfoModal(true)
        const { lngLat, features } = event

        const currentFeature = features[0]
        const attr = JSON.parse(currentFeature.properties.attributes)

        setSubPathNodeInfoModal({
            geometry: currentFeature.geometry,
            properties: currentFeature.properties,
            attributes: attr,
            type: currentFeature.properties.type,
            eid: currentFeature.properties.eid,
            id: currentFeature.properties.id,
            longitude: lngLat.lng,
            latitude: lngLat.lat,
        })
    }

    const handleCentroidModalInfoClose = () => {
        if (_network.centroid.available) {
            setOpenCentroidInfoModal(false)
            setCentroidClickSelectedData(null)
            mapbox.setFilter('aimsunLive_centroid_click_selected', ['in', 'id', ''])

            const foundFromData = { ...lineFromConnectData }
            foundFromData.features[0].geometry.coordinates = []

            const foundToData = { ...lineToConnectData }
            foundToData.features[0].geometry.coordinates = []

            mapbox.getSource('aimsunLive_centroid_line_from_connect_source').setData(foundFromData)
            mapbox.getSource('aimsunLive_centroid_line_to_connect_source').setData(foundToData)

            setLineFromConnectData(foundFromData)
            setLineToConnectData(foundToData)
        }
    }

    const handleOpenChart = (event: any) => {
        const { features } = event
        const { eid, type } = features[0].properties
        const nowDatatype =
            (type === 'GKDetector' && viewMode.sources.stations.now) ||
            (type === 'GKSection' && viewMode.sources.sections.now)
        if (!event.originalEvent.defaultPrevented && nowDatatype === 'real') {
            event.originalEvent.preventDefault()
            const value = features[0].state[viewMode.variable]
            const reliability = features[0].state.reliability
            const valueColor = getColorByValue(viewMode, value)
            const reliabilityColor = getColorByReliability(viewMode, reliability)
            dispatch(
                loadStationSelected({
                    mapId: mapId,
                    feature_id: eid,
                    t: _epoch,
                    viewMode: viewMode,
                    valueColor: valueColor,
                    reliabilityColor: reliabilityColor,
                })
            )
            dispatch(setOpenChart({ mapId: mapId, openChart: true }))
        }
    }

    const handleOpenEventsDialog = (event: any) => {
        if (!event.originalEvent.defaultPrevented) {
            event.originalEvent.preventDefault()
            const { features } = event
            dispatch(storeFeatureEventsSelected({ ...features[0], coords: event.lngLat }))
            dispatch(setOpenEventsDialog(true))
        }
    }

    const handleOpenEditEventsDialog = (event: any) => {
        if (!event.originalEvent.defaultPrevented) {
            event.originalEvent.preventDefault()
            const { features } = event
            dispatch(storeFeatureEventsSelected({ ...features[0], coords: event.lngLat }))
            dispatch(setOpenEventsDialog(true))
        }
    }

    const handleChangeViewMode = (viewModeId: number) => {
        const landUseViewOption: string = optionLandUseView || ''
        const manageViewOption: number = optionManageView || 0
        changeViewMode(_epoch, viewModeId, mapId, isBaseline, landUseViewOption, manageViewOption)
    }

    const handleToggleDetectors = () => {
        dispatch(toggleDetectors(mapId))
    }

    const handleToggleLocal = () => {
        dispatch(toggleLocal(mapId))
    }

    const handleToggleCentroid = () => {
        dispatch(toggleCentroid(mapId))
    }

    const handleToggleOthophoto = () => {
        dispatch(toggleOrthophoto(mapId))
    }

    const handleToggleUrban = () => {
        dispatch(toggleUrban1(mapId))
        dispatch(toggleUrban2(mapId))
    }

    const handleToggleSuburban = () => {
        dispatch(toggleSuburban(mapId))
    }

    const handleToggleMotorways = () => {
        dispatch(toggleMotorways(mapId))
    }

    const handleToggleDetectedIncidents = () => {
        dispatch(toggleDetectedIncidents(mapId))
    }

    const handleToggleIncidents = () => {
        dispatch(toggleReportedIncidents(mapId))
    }

    const handleToggleTestEvents = () => {
        dispatch(toggleTestEvents(mapId))
    }

    const handleToggleRealEvents = () => {
        dispatch(toggleRealEvents(mapId))
    }

    const handleToggleExternalIncident = () => {
        dispatch(toggleExternalIncidents(mapId))
    }

    const handleToggleTrafficAction = () => {
        dispatch(toggleActions(mapId))
    }

    const refreshDetectorLayer = () => {
        try {
            setDetectorsLayer({
                id: 'aimsunLive_detectors',
                type: 'circle',
                source: 'aimsunLive_detectors_source',
                paint: {
                    'circle-color': getColorStyles(viewMode),
                    'circle-stroke-width': 2,
                    'circle-stroke-color': getBorderDetectorStyles(viewMode),
                },
            })
        } catch (error) {
            console.log('refreshDetectorLayer()', error)
        }
    }

    const onCloseNewActionModal = () => {
        setOpenSubPathInfoModal(false)
    }
    const highlighSubpaths = () => {
        mapbox.setPaintProperty('aimsunLive_subpaths', 'line-color', SUBPATH_NODES)
        mapbox.setPaintProperty('aimsunLive_subpaths', 'line-width', [
            'interpolate',
            ['linear'],
            ['zoom'],
            12,
            6,
            14,
            6,
            15,
            6,
            16,
            8,
            17,
            10,
            18,
            12,
        ])
        mapbox.setPaintProperty('aimsunLive_subpaths', 'line-opacity', [
            'interpolate',
            ['linear'],
            ['zoom'],
            12,
            1,
            14,
            1,
            15,
            1,
            16,
            0.8,
            17,
            0.7,
            18,
            0.5,
        ])
    }

    const highlightNodes = () => {
        const nodesLayer = mapbox.getLayer('aimsunLive_nodes')
        if (nodesLayer) {
            mapbox.setPaintProperty('aimsunLive_nodes', 'circle-color', SUBPATH_NODES)
            mapbox.setPaintProperty('aimsunLive_nodes', 'circle-stroke-color', NAVY_BLUE)
            mapbox.setPaintProperty('aimsunLive_nodes', 'circle-stroke-width', 2)
            mapbox.setPaintProperty('aimsunLive_nodes', 'circle-radius', [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                3,
                14,
                3,
                15,
                3,
                16,
                5,
                17,
                6,
                18,
                7,
            ])
            mapbox.setPaintProperty('aimsunLive_nodes', 'circle-opacity', 1)
        }
    }

    const hideSignalizedNodes = () => {
        if (mapbox) {
            const layer = mapbox.getLayer('aimsunLive_nodes')
            if (layer) {
                mapbox.setPaintProperty('aimsunLive_nodes', 'circle-radius', 0)
                mapbox.setPaintProperty('aimsunLive_nodes', 'circle-opacity', 0)
                mapbox.setPaintProperty('aimsunLive_nodes', 'circle-stroke-width', 0)
            }
        }
    }

    const refreshSectionLayer = (sectionType: string, setSectionLayer: (layer: LineLayer) => void) => {
        try {
            setSectionLayer({
                id: `aimsunLive_${sectionType}`,
                type: 'line',
                source: `aimsunLive_${sectionType}_source`,
                paint: {
                    'line-color': _eventsIsSelected ? SUBPATH_NODES : getColorStyles(viewMode),
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        ['step', ['feature-state', viewMode.variable], 1, -200, 2, -1, 1, 0, 2],
                        14,
                        ['step', ['feature-state', viewMode.variable], 1.5, -200, 2, -1, 1.5, 0, 2],
                        15,
                        2,
                        16,
                        6,
                        17,
                        10,
                        18,
                        14,
                    ],
                    'line-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        ['step', ['feature-state', viewMode.variable], 0.5, -200, 1, -1, 0.5, 0, 1],
                        14,
                        1,
                        15,
                        1,
                        16,
                        0.8,
                        17,
                        0.7,
                        18,
                        0.5,
                    ],
                },
                layout: {
                    'line-cap': 'square',
                },
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const refreshPublicTransportSectionLayer = (sectionType: string, setSectionLayer: (layer: LineLayer) => void) => {
        try {
            setSectionLayer({
                id: `aimsunLive_${sectionType}`,
                type: 'line',
                source: `aimsunLive_${sectionType}_source`,
                paint: {
                    'line-color': getColorStyles(viewMode),
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        ['step', ['feature-state', viewMode.variable], 1, -200, 2, -1, 1, 0, 2],
                        14,
                        ['step', ['feature-state', viewMode.variable], 1.5, -200, 2, -1, 1.5, 0, 2],
                        15,
                        2,
                        16,
                        6,
                        17,
                        10,
                        18,
                        14,
                    ],
                    'line-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        ['step', ['feature-state', viewMode.variable], 0.5, -200, 1, -1, 0.5, 0, 1],
                        14,
                        1,
                        15,
                        1,
                        16,
                        0.8,
                        17,
                        0.7,
                        18,
                        0.5,
                    ],
                },
                filter: ['match', 'id', '', true, false],
                layout: {
                    'line-cap': 'square',
                },
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const refreshCentroidLayer = (sectionType: string, setSectionLayer: (layer: FillLayer) => void) => {
        try {
            setSectionLayer({
                id: `aimsunLive_${sectionType}`,
                type: 'fill',
                source: `aimsunLive_${sectionType}`,
                paint: {
                    'fill-color': '#000000',
                    'fill-outline-color': '#000000',
                    'fill-opacity': 0.1,
                },
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const refreshCentroidHoverSelectedLayer = (sectionType: string, setSectionLayer: (layer: FillLayer) => void) => {
        try {
            setSectionLayer({
                id: `aimsunLive_${sectionType}`,
                type: 'fill',
                source: `aimsunLive_centroid`,
                minzoom: 9,
                paint: {
                    'fill-color': '#38d315',
                    'fill-opacity': 0.3,
                    'fill-antialias': true,
                    'fill-outline-color': '#d31515',
                },
                filter: ['in', 'id', ''],
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const refreshCentroidClickSelectedLayer = (sectionType: string, setSectionLayer: (layer: FillLayer) => void) => {
        try {
            setSectionLayer({
                id: `aimsunLive_${sectionType}`,
                type: 'fill',
                source: `aimsunLive_centroid`,
                minzoom: 9,
                paint: {
                    'fill-color': '#e0570f',
                    'fill-opacity': 0.3,
                    'fill-antialias': true,
                    'fill-outline-color': '#d31515',
                },
                filter: ['in', 'id', ''],
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const getPtLinesIdsFromFeatures = (features: Feature<Geometry, GeoJsonProperties>[], ptLine: any) => {
        const featuresInSelectedPtLine = features.filter(feature => {
            const ptLinesFeature = JSON.parse(feature.properties?.ptlines)
            const ptLineInFeature = ptLinesFeature.find((ptFeature: any) => ptFeature.eid === ptLine.data.eid)

            return ptLineInFeature !== undefined
        })

        const uniqueIds = new Set(
            featuresInSelectedPtLine.map(feature => {
                return feature.properties?.id
            })
        )

        return [...uniqueIds]
    }

    const getHighlightOrClearPtLine = (ptLine: any) => {
        const features: Feature<Geometry, GeoJsonProperties>[] = mapbox.querySourceFeatures(
            'aimsunLive_public_transport_source',
            {
                sourceLayer: 'geogksection',
            }
        )

        if (features.length > 0) {
            return getPtLinesIdsFromFeatures(features, ptLine)
        } else {
            return []
        }
    }

    const highlightSelectedDiversionRoutes = (routeToCheck: any, color: string) => {
        const newDiversion = { ...diversionRouteSource }
        newDiversion.data.features[0].geometry.coordinates = routeToCheck.coordinates
        const id = routeToCheck.id
        const source = mapbox.getSource(`new-diversion-route-${id}-source`, newDiversion)
        if (!source) {
            mapbox.addSource(`new-diversion-route-${id}-source`, newDiversion)
            mapbox.addLayer({
                ...diversionRouteLayer,
                id: `new-diversion-route-${id}-layer`,
                source: `new-diversion-route-${id}-source`,
                paint: {
                    ...diversionRouteLayer.paint,
                    'line-color': color,
                },
            })
        }
    }

    const clearSelectedDiversionRoutes = (routeToCheck: any) => {
        mapbox.removeLayer(`new-diversion-route-${routeToCheck.id}-layer`)
        mapbox.removeSource(`new-diversion-route-${routeToCheck.id}-source`)
    }

    const highlightDiversionRoutes = (diversionRoute: any, color: string) => {
        const newDiversion = { ...diversionRouteSource }
        newDiversion.data.features[0].geometry.coordinates = diversionRoute.coordinates

        mapbox.addSource('highlight-diversion-route-source', newDiversion)
        mapbox.addLayer({
            ...diversionRouteLayer,
            id: 'highlight-diversion-route',
            source: 'highlight-diversion-route-source',
            paint: {
                ...diversionRouteLayer.paint,
                'line-color': color,
            },
        })
    }

    const clearDiversionRoutes = () => {
        mapbox.removeLayer('highlight-diversion-route')
        mapbox.removeSource('highlight-diversion-route-source')
    }

    const checkedDiversionRoutes = (checked: boolean, routeToCheck: any, color: string) => {
        if (checked) {
            highlightSelectedDiversionRoutes(routeToCheck, color)
        } else {
            clearSelectedDiversionRoutes(routeToCheck)
        }
    }

    const highlightPtLine = (ptLine: any) => {
        mapbox.setFilter('aimsunLive_public_transport_highlight', ['match', ['get', 'id'], '', true, false])

        const featuresInSelectedPtLineIds = getHighlightOrClearPtLine(ptLine)
        setPtLinesSelectedToHighlight(ptLinesSelectedToHighlight.concat(featuresInSelectedPtLineIds))
    }

    const clearPtLine = () => {
        mapbox.setFilter('aimsunLive_public_transport_highlight', ['match', ['get', 'id'], '', true, false])
        setPtLinesSelectedToHighlight([])
    }

    const checkedPtLine = (checked: boolean, ptLine: any) => {
        if (checked) {
            setPtLinesSelected(ptLinesSelected.concat(ptLine))
        } else {
            const ptLinesSelectedIds = [...ptLinesSelected]
            const ptLinesSelectedIdsFiltered = ptLinesSelectedIds.filter(ptSelected => {
                return ptSelected.data.eid !== ptLine.data.eid
            })
            setPtLinesSelected(ptLinesSelectedIdsFiltered)
        }
    }

    const refreshLayers = () => {
        if (_network.detectors.available) {
            refreshDetectorLayer()
        }
        if (_network.centroid.available) {
            refreshCentroidLayer('centroid', setCentroidLayer)
            refreshCentroidHoverSelectedLayer('centroid_selected', setCentroidLayerSelected)
            refreshCentroidClickSelectedLayer('centroid_click_selected', setCentroidClickLayerSelected)
            refreshCentroidClickSelectedLayer('centroid_edited', setCentroidEdited)
        }
        if (_network.motorways.available) {
            refreshSectionLayer('motorways', setMotorwaysLayer)
        }
        if (_network.suburban.available) {
            refreshSectionLayer('suburban', setSuburbanLayer)
        }
        if (_network.urban_1.available) {
            refreshSectionLayer('urban_1', setUrban1Layer)
        }
        if (_network.urban_2.available) {
            refreshSectionLayer('urban_2', setUrban2Layer)
        }
        if (_network.local.available) {
            refreshSectionLayer('local', setLocalLayer)
        }
        if (_network.public_transport.available) {
            refreshPublicTransportSectionLayer('public_transport', setPublicTransportLayer)
        }
    }

    const resetFoundFeatureTooltip = (_event: MapEvent) => {
        setShowFoundFeaTureTooltip(false)
        setFoundFeaTureTooltipData(null)
        setFoundFeaTureTooltipPosition({ longitude: 0, latitude: 0 })
    }

    const hideCentroidTooltip = (_event: MapEvent) => {
        mapbox.setFilter('aimsunLive_centroid_selected', ['in', 'id', ''])
        setShowCentroidTooltip(false)
    }

    const hideFeatureTooltip = (_event: MapEvent) => {
        setShowFeatureTooltip(false)
    }

    const hideRiskPredictionTooltip = (_event: MapEvent) => {
        setShowRiskPredictionTooltip(false)
    }

    const renderRiskPredictionTypeTooltip = (event: any) => {
        renderRiskPredictionTooltip(event, 'risk_prediction')
    }

    const hideSpeedRecommendationTooltip = (_event: MapEvent) => {
        setShowSpeedRecommendationTooltip(false)
    }

    const renderSpeedRecommendationTypeTooltip = (event: any) => {
        renderSpeedRecommendationTooltip(event, 'speed_recommendation')
    }

    const hideActionsTooltip = (_event: MapEvent) => {
        setShowActionsTooltip(false)
    }

    const renderActionsTypeTooltip = (event: any) => {
        renderActionsTooltip(event)
    }

    const addMapListeners = () => {
        mapbox._delegatedListeners = {}
        mapbox._listeners['click'] = []
        mapbox._listeners['mousemove'] = []
        mapbox._listeners['mouseout'] = []

        mapbox
            .on('mousemove', 'aimsunLive_centroid', renderCentroidTooltip)
            .on('mouseout', 'aimsunLive_centroid', hideCentroidTooltip)
            .on('mousemove', 'aimsunLive_local', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_local', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_motorways', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_motorways', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_suburban', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_suburban', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_urban_1', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_urban_1', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_urban_2', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_urban_2', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_detectors', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_detectors', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_public_transport', renderFeatureTooltip)
            .on('mouseout', 'aimsunLive_public_transport', hideFeatureTooltip)
            .on('mousemove', 'aimsunLive_risk_prediction', renderRiskPredictionTypeTooltip)
            .on('mouseout', 'aimsunLive_risk_prediction', hideRiskPredictionTooltip)
            .on('mousemove', 'aimsunLive_speed_recommendation', renderSpeedRecommendationTypeTooltip)
            .on('mouseout', 'aimsunLive_speed_recommendation', hideSpeedRecommendationTooltip)
            .on('mousemove', 'aimsunLive_traffic_actions', renderActionsTypeTooltip)
            .on('mouseout', 'aimsunLive_traffic_actions', hideActionsTooltip)
            .on('click', 'aimsunLive_centroid', handleOpenCentroidInfo)
            .on('click', 'aimsunLive_subpaths', handleCreateActionPanel)
            .on('click', 'aimsunLive_nodes', handleCreateActionPanel)
            .on('click', resetFoundFeatureTooltip)
            .on('zoomend', refreshAllAvailableFeatures)
            .on('moveend', refreshAllAvailableFeatures)
        if (
            viewMode.sources.stations.now === 'real' &&
            viewMode.sources.stations.future !== 'na' &&
            !_eventsIsSelected
        ) {
            mapbox.on('click', 'aimsunLive_detectors', handleOpenChart)
        }
        if (
            sectionOnClick &&
            viewMode.sources.sections.now === 'real' &&
            viewMode.sources.sections.future !== 'na' &&
            !_eventsIsSelected
        ) {
            mapbox
                .on('click', 'aimsunLive_local', handleOpenChart)
                .on('click', 'aimsunLive_motorways', handleOpenChart)
                .on('click', 'aimsunLive_suburban', handleOpenChart)
                .on('click', 'aimsunLive_urban_1', handleOpenChart)
                .on('click', 'aimsunLive_urban_2', handleOpenChart)
        }
        if (_events && _eventsIsSelected) {
            for (const [key] of Object.entries(_events)) {
                const layerId: string = `aimsunLive_events_${key}`
                mapbox
                    .on('mousemove', layerId, (event: any) => {
                        renderEventTooltip(event)
                    })
                    .on('mouseout', layerId, () => {
                        setShowEventTooltip(false)
                    })
                    .on('click', layerId, (event: any) => {
                        handleOpenEditEventsDialog(event)
                    })
            }
        }
        if (_eventsIsSelected) {
            mapbox
                .on('click', 'aimsunLive_local', handleOpenEventsDialog)
                .on('click', 'aimsunLive_motorways', handleOpenEventsDialog)
                .on('click', 'aimsunLive_suburban', handleOpenEventsDialog)
                .on('click', 'aimsunLive_urban_1', handleOpenEventsDialog)
                .on('click', 'aimsunLive_urban_2', handleOpenEventsDialog)
        }
        if (_incidents) {
            for (const [key] of Object.entries(_incidents)) {
                const layerId: string = `aimsunLive_${key}`
                mapbox
                    .on('mousemove', layerId, (event: any) => {
                        renderIncidentTooltip(event, key)
                    })
                    .on('mouseout', layerId, () => {
                        setShowIncidentTooltip(false)
                    })
            }
        }
    }

    const refreshAllAvailableFeatures = () => {
        if (_network.detectors.available) {
            refreshFeatureStyleData(_mapboxData.stationData, 'aimsunLive_detectors_source', 'geogkdetector')
        }
        if (_network.motorways.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_motorways_source', 'geogksection')
        }
        if (_network.suburban.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_suburban_source', 'geogksection')
        }
        if (_network.urban_1.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_urban_1_source', 'geogksection')
        }
        if (_network.urban_2.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_urban_2_source', 'geogksection')
        }
        if (_network.local.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_local_source', 'geogksection')
        }
        if (_network.public_transport.available) {
            refreshFeatureStyleData(_mapboxData.sectionData, 'aimsunLive_public_transport_source', 'geogksection')
        }
        if (_mapboxData.riskPrediction) {
            refreshRiskPrediction()
        }
        if (_mapboxData) {
            _selectedEvaluation.id > 0
                ? refreshTrafficActions(_mapboxData.rpActions)
                : refreshTrafficActions(_trafficActions)
        }
    }

    const refreshFeatureStyleData = (data: any[], source: string, sourceLayer: string) => {
        try {
            const _viewMode: IViewMode = viewMode
            const features: Feature<Geometry, GeoJsonProperties>[] = mapbox.querySourceFeatures(source, {
                sourceLayer,
            })
            features.forEach(feature => {
                const foundData: any = data[feature.properties?.eid] || data[feature.properties?.id]
                const _value = foundData?.value
                const _variable = _viewMode.variable
                if (_value != null) {
                    const numberValue = Number(_value)
                    const _reliability = foundData?.reliability

                    const featureState = {
                        [_variable]: numberValue,
                        reliability: _reliability,
                    }

                    mapbox.setFeatureState(
                        {
                            source,
                            sourceLayer,
                            id: feature.properties?.id,
                        },
                        featureState
                    )
                } else {
                    const featureState = {
                        [_variable]: -1,
                        reliability: -1,
                    }

                    mapbox.setFeatureState(
                        {
                            source,
                            sourceLayer,
                            id: feature.properties?.id,
                        },
                        featureState
                    )
                }
            })
        } catch (error) {
            console.log('refreshFeatureStyleData()', error)
        }
    }

    const networkSpeedDial = [
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.detectors}`)
                : t('mapbox.detectors'),
            key: `detectors`,
            onClick: handleToggleDetectors,
            icon: <CustomIcon name={_visibility[mapId].detectors ? 'detectorsActive' : 'detectors'} />,
            selected: _visibility[mapId].detectors ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.local}`)
                : t('mapbox.local'),
            key: `local`,
            onClick: handleToggleLocal,
            icon: <CustomIcon name={_visibility[mapId].local ? 'localActive' : 'local'} />,
            selected: _visibility[mapId].local ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.urban}`)
                : t('mapbox.urban'),
            key: `urban`,
            onClick: handleToggleUrban,
            icon: <CustomIcon name={_visibility[mapId].urban_1 ? 'urbanActive' : 'urban'} />,
            selected: _visibility[mapId].urban_1 ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.suburban}`)
                : t('mapbox.suburban'),
            key: `suburban`,
            onClick: handleToggleSuburban,
            icon: <CustomIcon name={_visibility[mapId].suburban ? 'suburbanActive' : 'suburban'} />,
            selected: _visibility[mapId].suburban ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.motorways}`)
                : t('mapbox.motorways'),
            key: `motorways`,
            onClick: handleToggleMotorways,
            icon: <CustomIcon name={_visibility[mapId].motorways ? 'motorwaysActive' : 'motorways'} />,
            selected: _visibility[mapId].motorways ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.centroid}`)
                : t('mapbox.centroid'),
            key: `centroid`,
            onClick: handleToggleCentroid,
            icon: <CustomIcon name={_visibility[mapId].centroid ? 'centroidActive' : 'centroid'} />,
            selected: _visibility[mapId].centroid ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.orthophoto}`)
                : t('mapbox.orthophoto'),
            key: `orthophoto`,
            onClick: handleToggleOthophoto,
            icon: <CustomIcon name={_visibility[mapId].orthophoto ? 'aerial_imageryActive' : 'aerial_imagery'} />,
            selected: _visibility[mapId].orthophoto ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
    ]

    const incidentsSpeedDial = [
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.detected}`)
                : t('mapbox.detected'),
            key: `detected`,
            onClick: handleToggleDetectedIncidents,
            icon: <CustomIcon name={_visibility[mapId].detected ? 'detectedEventWhite' : 'detectedEventLight'} />,
            selected: _visibility[mapId].detected ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.incidents}`)
                : t('mapbox.incidents'),
            key: `incidents`,
            onClick: handleToggleIncidents,
            icon: <CustomIcon name={_visibility[mapId].reported ? 'eventWhite' : 'eventLight'} />,
            selected: _visibility[mapId].reported ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.test}`)
                : t('mapbox.test'),
            key: `test`,
            onClick: handleToggleTestEvents,
            icon: <CustomIcon name={_visibility[mapId].test ? 'eventWhite' : 'eventLight'} />,
            selected: _visibility[mapId].test ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.real}`)
                : t('mapbox.real'),
            key: `real`,
            onClick: handleToggleRealEvents,
            icon: <CustomIcon name={_visibility[mapId].real ? 'eventWhite' : 'eventLight'} />,
            selected: _visibility[mapId].real ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.external}`)
                : t('mapbox.external'),
            key: `external`,
            onClick: handleToggleExternalIncident,
            icon: <CustomIcon name={_visibility[mapId].external ? 'externalEventWhite' : 'externalEventLight'} />,
            selected: _visibility[mapId].external ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
    ]

    const actionsSpeedDial = [
        {
            tooltipTitle: _moduleConfig.speed_dials_names
                ? t(`${_moduleConfig.speed_dials_names.trafficActions}`)
                : t('mapbox.trafficActions'),
            key: `traffic_actions`,
            onClick: handleToggleTrafficAction,
            icon: <CustomIcon name={_visibility[mapId].traffic_actions ? 'actionWhite' : 'actionLight'} />,
            selected: _visibility[mapId].traffic_actions ? 'selected' : '',
            FabProps: { size: 'large' },
            tooltipPlacement: 'left',
            tooltipOpen: true,
        },
    ]

    const handleSearch = (criteria: string): void => {
        dispatch(
            loadSearchFeature({
                mapId: mapId,
                criteria: criteria,
                searchIdentifier: _moduleConfig.searchIdentifier,
            })
        )
    }

    const handleListSearchClick = (featureSelectedId: any): void => {
        const foundSelectedProperties = _foundFeatureList.find((feature: any) => feature.id === featureSelectedId)
        if (foundSelectedProperties) {
            const zoom = foundSelectedProperties.type === 'centroid' ? 14 : 18
            // @ts-ignore
            mapbox.flyTo({
                center: [foundSelectedProperties.position[0], foundSelectedProperties.position[1]],
                zoom,
                duration: 2000,
                essential: true,
            })
        }

        setTimeout(() => {
            if (foundSelectedProperties) {
                const found = getFeature(
                    featureSelectedId,
                    foundSelectedProperties,
                    mapbox,
                    _moduleConfig.searchIdentifier
                )
                if (found?.id) {
                    setFoundFeature(found)
                    goToFeature(found)
                }
            }
        }, 2050)
    }

    const handleZoomIn = (): void => {
        const currentZoom: number = mapbox.getZoom()
        const nextZoom: number = currentZoom + 1
        mapbox.easeTo({ zoom: nextZoom })
    }

    const handleZoomOut = (): void => {
        const currentZoom: number = mapbox.getZoom()
        const nextZoom: number = currentZoom - 1
        mapbox.easeTo({ zoom: nextZoom })
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setFeedbackOpen(false)
    }

    const riskPrediction: any = _mapboxData.riskPrediction
    const speedRecommendation: any = _mapboxData.speedRecommendation
    const actionsExist: any = _mapboxData.rpActions || _trafficActions
    const actionsData: any = _trafficActions.features.length > 0 ? _trafficActions : _mapboxData.rpActions
    const showNetworkTooltip =
        showFeatureTooltip &&
        !showRiskPredictionTooltip &&
        !showIncidentTooltip &&
        !showActionsTooltip &&
        !showSpeedRecommendationTooltip &&
        !showEventTooltip

    const getValueToShowOnTooltip = (value: string | undefined, unit: string, units: string): string => {
        if (Number(value) === -200) {
            return t('common.existingCongestion')
        } else if (Number(value) === -1) {
            return t('common.noData')
        } else if (Number(value) > -1) {
            if (units === 'imperial') {
                const unitType = getUnitTypeFromUnit(unit)
                if (unitType) {
                    const imperialValue = converterToImperialSystem(unitType, Number(value))
                    const imperialUnit = convertUnitsFromMetricToImperial(unit)
                    return ` ${Math.ceil(Number(imperialValue))} ${t(`units.${imperialUnit}`)}`
                }
            }
            return ` ${Math.ceil(Number(value))} ${t(`units.${unit}`)}`
        } else {
            return t('common.noData')
        }
    }

    return (
        <MapboxWrapper>
            {_mapboxData.fetchingData && (
                <MapBoxLoaderWrapper width={viewport.width} height={viewport.height}>
                    <MapBoxLoaderMaterial size={26} margin={7} color='#E9A59D' />
                </MapBoxLoaderWrapper>
            )}
            <MapBoxLoader
                sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
                open={_appStatus === 'loading' && !_mapboxData.fetchingData}
                width={viewport.width}
                height={viewport.height}>
                <CircularProgress color='inherit' />
            </MapBoxLoader>
            <Map
                initialViewState={{
                    latitude: _moduleConfig.position.latitude,
                    longitude: _moduleConfig.position.longitude,
                    zoom: _moduleConfig.position.zoom,
                }}
                style={{ width: viewport.width, height: viewport.height }}
                mapStyle={_mapboxStyle}
                mapboxAccessToken={_mapboxToken}
                reuseMaps={false}
                keyboard={false}
                dragRotate={false}
                touchZoomRotate={false}
                trackResize={true}
                logoPosition='top-right'
                interactiveLayerIds={[
                    'aimsunLive_detectors, ' +
                        'aimsunLive_motorways, ' +
                        'aimsunLive_suburban, ' +
                        'aimsunLive_urban, ' +
                        'aimsunLive_local, ' +
                        'aimsunLive_public_transport, ' +
                        'aimsunLive_external, ' +
                        'aimsunLive_real, ' +
                        'aimsunLive_test, ' +
                        'aimsunLive_nodes' +
                        'aimsunLive_subpaths' +
                        'aimsunLive_detected',
                ]}
                onLoad={onLoad}
                onMouseOver={() => {
                    handleHover()
                }}>
                {_network.centroid.available && (
                    <>
                        <Source
                            type='vector'
                            tiles={[
                                `${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogkcentroid/{z}/{x}/{y}`,
                            ]}
                            maxzoom={_network.centroid.maxzoom}
                            minzoom={_network.centroid.minzoom}
                            bounds={_network.centroid.bounds}
                            id='aimsunLive_centroid_source'>
                            <Layer {...centroidLayer} />
                            <Layer {...centroidLayerSelected} />
                            <Layer {...centroidClickLayerSelected} />
                            <Layer {...centroidEdited} />
                        </Source>
                        <Source type='geojson' data={lineToConnectData} id='aimsunLive_centroid_line_to_connect_source'>
                            <Layer {...lineToConnectCentroidState} />
                        </Source>
                        <Source
                            type='geojson'
                            data={lineFromConnectData}
                            id='aimsunLive_centroid_line_from_connect_source'>
                            <Layer {...lineFromConnectCentroidState} />
                        </Source>
                    </>
                )}
                {_network.public_transport.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geoptlayer/{z}/{x}/{y}`]}
                        maxzoom={_network.public_transport.maxzoom}
                        minzoom={_network.public_transport.minzoom}
                        bounds={_network.public_transport.bounds}
                        id='aimsunLive_public_transport_source'>
                        <Layer {...publicTransportHighlightLayer} />
                        <Layer {...publicTransportLayer} />
                    </Source>
                )}
                {_network.motorways.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksection_1/{z}/{x}/{y}`]}
                        maxzoom={_network.motorways.maxzoom}
                        minzoom={_network.motorways.minzoom}
                        bounds={_network.motorways.bounds}
                        id='aimsunLive_motorways_source'>
                        <Layer {...motorwaysLayer} />
                    </Source>
                )}
                {_network.suburban.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksection_2/{z}/{x}/{y}`]}
                        maxzoom={_network.suburban.maxzoom}
                        minzoom={_network.suburban.minzoom}
                        bounds={_network.suburban.bounds}
                        id='aimsunLive_suburban_source'>
                        <Layer {...suburbanLayer} />
                    </Source>
                )}
                {_network.urban_1.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksection_3/{z}/{x}/{y}`]}
                        maxzoom={_network.urban_1.maxzoom}
                        minzoom={_network.urban_1.minzoom}
                        bounds={_network.urban_1.bounds}
                        id='aimsunLive_urban_1_source'>
                        <Layer {...urban1Layer} />
                    </Source>
                )}
                {_network.urban_2.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksection_4/{z}/{x}/{y}`]}
                        maxzoom={_network.urban_2.maxzoom}
                        minzoom={_network.urban_2.minzoom}
                        bounds={_network.urban_2.bounds}
                        id='aimsunLive_urban_2_source'>
                        <Layer {...urban2Layer} />
                    </Source>
                )}
                {_network.local.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksection_5/{z}/{x}/{y}`]}
                        maxzoom={_network.local.maxzoom}
                        minzoom={_network.local.minzoom}
                        bounds={_network.local.bounds}
                        id='aimsunLive_local_source'>
                        <Layer {...localLayer} />
                    </Source>
                )}
                {_network.subpaths.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogksubpath/{z}/{x}/{y}`]}
                        maxzoom={_network.subpaths.maxzoom}
                        minzoom={_network.subpaths.minzoom}
                        bounds={_network.subpaths.bounds}
                        id='aimsunLive_subpath_source'>
                        <Layer {...subpathsLayerInitialState} />
                    </Source>
                )}
                {_network.nodes.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles//${_mapHorizonSelected.id}/geogknode/{z}/{x}/{y}`]}
                        maxzoom={_network.nodes.maxzoom}
                        minzoom={_network.nodes.minzoom}
                        bounds={_network.nodes.bounds}
                        id='aimsunLive_nodes_source'>
                        <Layer {...nodesLayerInitialState} />
                    </Source>
                )}
                {_network.detectors.available && (
                    <Source
                        type='vector'
                        tiles={[`${window.location.origin}/tiles/${_mapHorizonSelected.id}/geogkdetector/{z}/{x}/{y}`]}
                        maxzoom={_network.detectors.maxzoom}
                        minzoom={_network.detectors.minzoom}
                        bounds={_network.detectors.bounds}
                        id='aimsunLive_detectors_source'>
                        <Layer {...detectorsLayer} />
                    </Source>
                )}
                {_moduleConfig.showSpeedRecommendation && speedRecommendation && (
                    <Source type='geojson' data={speedRecommendation} id='aimsunLive_speed_recommendation_source'>
                        <Layer {...speedRecommendationLayerInitialState} />
                    </Source>
                )}
                {_incidents.external && (
                    <Source type='geojson' data={_incidents.external} id='aimsunLive_external_source'>
                        <Layer {...externalIncidentsLayerInitialState} />
                    </Source>
                )}
                {_incidents.detected && (
                    <Source type='geojson' data={_incidents.detected} id='aimsunLive_detected_source'>
                        <Layer {...detectedIncidentsLayerInitialState} />
                    </Source>
                )}
                {_incidents.real && (
                    <Source type='geojson' data={_incidents.real} id='aimsunLive_real_source'>
                        <Layer {...realIncidentsLayerInitialState} />
                    </Source>
                )}
                {_incidents.test && (
                    <Source type='geojson' data={_incidents.test} id='aimsunLive_test_source'>
                        <Layer {...testIncidentsLayerInitialState} />
                    </Source>
                )}
                {_events.real && (
                    <Source type='geojson' data={_events.real} id='aimsunLive_events_real_source'>
                        <Layer {...realEventsLayerInitialState} />
                    </Source>
                )}
                {_events.test && (
                    <Source type='geojson' data={_events.test} id='aimsunLive_events_test_source'>
                        <Layer {...testEventsLayerInitialState} />
                    </Source>
                )}
                {riskPrediction && (
                    <Source type='geojson' data={riskPrediction} id='aimsunLive_risk_prediction_source'>
                        <Layer {...riskPredictionIncidentsLayerInitialState} />
                    </Source>
                )}
                {actionsExist && (
                    <Source type='geojson' data={actionsData} id='aimsunLive_traffic_actions_source'>
                        <Layer {...trafficActionsLayerInitialState} />
                    </Source>
                )}

                {children}

                {showNetworkTooltip && (
                    <FeatureTooltip
                        longitude={featureTooltipPosition.longitude}
                        latitude={featureTooltipPosition.latitude}
                        anchor={featureTooltipData?.anchor}
                        offset={5}
                        maxWidth='none'
                        closeButton={false}
                        onClose={() => {
                            setShowFeatureTooltip(false)
                            setFeatureTooltipData(null)
                            setFeatureTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            {!_eventsIsSelected && (
                                <>
                                    <TooltipCircle
                                        style={{
                                            background: featureTooltipData?.valueColor,
                                        }}
                                    />
                                    <TooltipCircleBackground
                                        style={
                                            containerName !== 'Land use'
                                                ? { backgroundColor: featureTooltipData?.reliabilityColor }
                                                : { backgroundColor: WHITE }
                                        }
                                    />
                                </>
                            )}
                            {featureTooltipData?.featureType === 'GKSection' ? (
                                <HeaderText variant='h1'>{featureTooltipData?.idToShow}</HeaderText>
                            ) : (
                                <HeaderText variant='h1'>{featureTooltipData?.eid}</HeaderText>
                            )}
                        </TooltipHeader>
                        <TooltipContent>
                            {containerName !== 'Land use' && (
                                <TooltipText>
                                    {t('mapbox.id')}: {featureTooltipData?.id}
                                </TooltipText>
                            )}
                            {!_eventsIsSelected && (
                                <>
                                    <TooltipText>
                                        {t('mapbox.roadType')}:{' '}
                                        {_moduleConfig.speed_dials_names
                                            ? t(featureTooltipData?.roadType)
                                            : t(`mapbox.${featureTooltipData?.roadType}`)}
                                    </TooltipText>
                                    <TooltipText>
                                        {`${t(viewMode.label)}: `}
                                        {featureTooltipData?.value != null
                                            ? getValueToShowOnTooltip(
                                                  featureTooltipData?.value,
                                                  viewMode.units.main,
                                                  units
                                              )
                                            : `${t('common.noData')}`}
                                    </TooltipText>
                                    {containerName !== 'Land use' && (
                                        <TooltipText>
                                            {t('mapbox.reliability')}:{' '}
                                            {featureTooltipData?.reliability !== null &&
                                            Number(featureTooltipData?.reliability) > -1
                                                ? ` ${featureTooltipData?.reliability}`
                                                : `${t('common.noData')}`}
                                        </TooltipText>
                                    )}
                                    {containerName === 'Land use' &&
                                        featureTooltipData?.landUseExtraData &&
                                        featureTooltipData.landUseExtraData.map((data: ILandUseExtraData) => {
                                            return (
                                                <TooltipText key={data.label}>
                                                    {`${t(data.label)}: `}
                                                    {data?.value != null
                                                        ? getValueToShowOnTooltip(
                                                              data.value.toString(),
                                                              data.unit,
                                                              units
                                                          )
                                                        : `${t('common.noData')}`}
                                                </TooltipText>
                                            )
                                        })}
                                </>
                            )}
                        </TooltipContent>
                    </FeatureTooltip>
                )}
                {showIncidentTooltip && (
                    <FeatureTooltip
                        longitude={incidentTooltipPosition.longitude}
                        latitude={incidentTooltipPosition.latitude}
                        anchor={incidentTooltipData?.anchor}
                        offset={5}
                        maxWidth='380px'
                        closeButton={false}
                        onClose={() => {
                            setShowIncidentTooltip(false)
                            setIncidentTooltipData(null)
                            setIncidentTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            <IncidentIcon src={getIncidentIcon(incidentTooltipData?.source)} alt='incident-icon' />
                            <Typography style={{ textTransform: 'capitalize' }} variant='h1'>
                                {incidentTooltipData?.source} {t('mapbox.incident')}
                            </Typography>
                        </TooltipHeader>
                        <TooltipContent>
                            <TooltipText>{incidentTooltipData?.type}</TooltipText>
                            <TooltipText>{incidentTooltipData?.start_time}</TooltipText>
                            <TooltipText>{incidentTooltipData?.end_time}</TooltipText>
                            <TooltipText>{incidentTooltipData?.impact}</TooltipText>
                            <TooltipText>{incidentTooltipData?.in_simulation}</TooltipText>
                        </TooltipContent>
                    </FeatureTooltip>
                )}
                {showEventTooltip && (
                    <FeatureTooltip
                        longitude={eventTooltipPosition.longitude}
                        latitude={eventTooltipPosition.latitude}
                        anchor={eventTooltipData?.anchor}
                        offset={5}
                        maxWidth='380px'
                        closeButton={false}
                        onClose={() => {
                            setShowEventTooltip(false)
                            setEventTooltipData(null)
                            setEventTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            <IncidentIcon src={getIncidentIcon(eventTooltipData?.type)} alt='incident-icon' />
                            <Typography variant='h1'>{eventTooltipData?.title}</Typography>
                        </TooltipHeader>
                        <TooltipContent>
                            <TooltipText>{`${t('mapbox.section_Id')}: ${eventTooltipData?.sectionId}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.closedLane')}: ${eventTooltipData?.closeLanes}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.speeds')}: [${eventTooltipData?.speeds}]`}</TooltipText>
                            <TooltipText>{`${t('mapbox.start')}: ${eventTooltipData?.start_time}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.end')}: ${eventTooltipData?.end_time}`}</TooltipText>
                        </TooltipContent>
                    </FeatureTooltip>
                )}
                {showActionsTooltip && (
                    <IncidentTooltip
                        longitude={actionsTooltipPosition.longitude}
                        latitude={actionsTooltipPosition.latitude}
                        anchor={actionsTooltipData?.anchor}
                        offset={5}
                        maxWidth='380px'
                        closeButton={false}
                        onClose={() => {
                            setShowActionsTooltip(false)
                            setActionsTooltipData(null)
                            setActionsTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            <IncidentIcon src={getIncidentIcon('traffic_actions')} alt='actions-icon' />
                            {actionsTooltipData?.title && (
                                <Typography variant='h1'>{actionsTooltipData?.title}</Typography>
                            )}
                        </TooltipHeader>
                        <TooltipActionContent>
                            {actionsTooltipData?.description?.length && actionsTooltipData?.description?.length > 1 ? (
                                actionsTooltipData?.description.map((text: string) => {
                                    if (text !== '') {
                                        return (
                                            <Typography key={text} variant='h4'>
                                                {text}
                                            </Typography>
                                        )
                                    } else {
                                        return ''
                                    }
                                })
                            ) : (
                                <Typography variant='h4'>{actionsTooltipData?.description}</Typography>
                            )}
                            {actionsTooltipData?.section_id && (
                                <Typography variant='h4'>{`Section Id: ${actionsTooltipData?.section_id}`}</Typography>
                            )}
                            {actionsTooltipData?.subpaths?.length > 0 && (
                                <>
                                    <Typography variant='h4'>{'Subpaths: '}</Typography>
                                    <SubPathListHeader>
                                        {actionsTooltipData?.subpaths.map((subpath: any) => {
                                            return (
                                                <SubPathListList key={subpath.subpathId}>
                                                    {`${subpath.subpathId}: ${subpath.subpathPercentaje}%`}
                                                </SubPathListList>
                                            )
                                        })}
                                    </SubPathListHeader>
                                </>
                            )}
                            {actionsTooltipData?.node_id && (
                                <Typography variant='h4'>{`Node Id: ${actionsTooltipData?.node_id}`}</Typography>
                            )}
                            {actionsTooltipData?.control_plan_id && (
                                <Typography variant='h4'>{`Control Plan Id: ${actionsTooltipData?.control_plan_id}`}</Typography>
                            )}
                        </TooltipActionContent>
                    </IncidentTooltip>
                )}
                {showRiskPredictionTooltip && (
                    <IncidentTooltip
                        longitude={riskPredictionTooltipPosition.longitude}
                        latitude={riskPredictionTooltipPosition.latitude}
                        anchor={riskPredictionTooltipData?.anchor}
                        offset={5}
                        maxWidth='none'
                        closeButton={false}
                        onClose={() => {
                            setShowIncidentTooltip(false)
                            setIncidentTooltipData(null)
                            setIncidentTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            <IncidentIcon
                                src={getIncidentIcon(riskPredictionTooltipData?.source)}
                                alt='incident-icon'
                            />
                            <Typography variant='h1'>{riskPredictionTooltipData?.title}</Typography>
                        </TooltipHeader>
                        <TooltipContent>
                            <Typography variant='h4'>{riskPredictionTooltipData?.description}</Typography>
                        </TooltipContent>
                    </IncidentTooltip>
                )}
                {showSpeedRecommendationTooltip && (
                    <IncidentTooltip
                        longitude={speedRecommendationTooltipPosition.longitude}
                        latitude={speedRecommendationTooltipPosition.latitude}
                        anchor={speedRecommendationTooltipData?.anchor}
                        offset={5}
                        maxWidth='none'
                        closeButton={false}
                        onClose={() => {
                            setShowIncidentTooltip(false)
                            setIncidentTooltipData(null)
                            setIncidentTooltipPosition({ longitude: 0, latitude: 0 })
                        }}>
                        <TooltipHeader>
                            <IncidentIcon
                                src={getSpeedRecommendationIcon(speedRecommendationTooltipData?.value)}
                                alt='incident-icon'
                            />
                            <Typography variant='h1'>{speedRecommendationTooltipData?.title}</Typography>
                        </TooltipHeader>
                        <TooltipContent>
                            <Typography variant='h4'>
                                {`${t('mapbox.speed')}: ${speedRecommendationTooltipData?.value} ${
                                    speedRecommendationTooltipData?.unit
                                }`}
                            </Typography>
                            <Typography variant='h4'>
                                {`${t('mapbox.motivation')}: ${t(speedRecommendationTooltipData?.motivation || '')}`}
                            </Typography>
                        </TooltipContent>
                    </IncidentTooltip>
                )}
                {showFoundFeaTureTooltip && !_mapboxData.fetchingData && (
                    <FeatureTooltip
                        longitude={foundFeaTureTooltipPosition.longitude}
                        latitude={foundFeaTureTooltipPosition.latitude}
                        anchor={foundFeaTureTooltipData?.anchor}
                        offset={5}
                        maxWidth='none'
                        closeButton={false}
                        onClose={() => {}}>
                        <TooltipFoundFeature>
                            {foundFeaTureTooltipData?.featureType === 'GKSection' ? (
                                <HeaderText variant='h1'>{foundFeaTureTooltipData?.idToShow}</HeaderText>
                            ) : (
                                <HeaderText variant='h1'>{foundFeaTureTooltipData?.eid}</HeaderText>
                            )}
                        </TooltipFoundFeature>
                    </FeatureTooltip>
                )}
                {showCentroidTooltip && (
                    <FeatureTooltip
                        longitude={centroidTooltipPosition.longitude}
                        latitude={centroidTooltipPosition.latitude}
                        anchor={centroidTooltipData?.anchor}
                        offset={5}
                        maxWidth='none'
                        closeButton={false}
                        onClose={() => {}}>
                        <TooltipHeader>
                            <HeaderText variant='h1'>{centroidTooltipData?.eid}</HeaderText>
                        </TooltipHeader>
                        <TooltipContent>
                            <TooltipText>{`${t('mapbox.zone')}: ${centroidTooltipData?.eid}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.residential')}: ${Math.ceil(
                                centroidTooltipData?.data.residential
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.office')}: ${Math.ceil(
                                centroidTooltipData?.data.office
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.retail')}: ${Math.ceil(
                                centroidTooltipData?.data.retail
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.industrial')}: ${Math.ceil(
                                centroidTooltipData?.data.industrial
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.school')}: ${Math.ceil(
                                centroidTooltipData?.data.school
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.medical')}: ${Math.ceil(
                                centroidTooltipData?.data.medical
                            ).toLocaleString('en-US')} ${t('units.m2')}`}</TooltipText>
                            <TooltipText>{`${t('mapbox.hotelRooms')}: ${Math.ceil(
                                centroidTooltipData?.data.hotelrooms
                            ).toLocaleString('en-US')} ${t('units.rooms')}`}</TooltipText>
                            {(optionLandUseView === 'am' ||
                                optionLandUseView === 'am_baseline' ||
                                optionLandUseView === '') && (
                                <>
                                    <TooltipText>{`${t('mapbox.generatedAm')}: ${Math.ceil(
                                        centroidTooltipData?.data.am_generation
                                    ).toLocaleString('en-US')} ${t('units.trips')}`}</TooltipText>
                                    <TooltipText>{`${t('mapbox.attractedAm')}: ${Math.ceil(
                                        centroidTooltipData?.data.am_attraction
                                    ).toLocaleString('en-US')} ${t('units.trips')}`}</TooltipText>
                                </>
                            )}
                            {(optionLandUseView === 'pm' ||
                                optionLandUseView === 'pm_baseline' ||
                                optionLandUseView === '') && (
                                <>
                                    <TooltipText>{`${t('mapbox.generatedPm')}: ${Math.ceil(
                                        centroidTooltipData?.data.pm_generation
                                    ).toLocaleString('en-US')} ${t('units.trips')}`}</TooltipText>
                                    <TooltipText>{`${t('mapbox.attractedPm')}: ${Math.ceil(
                                        centroidTooltipData?.data.pm_attraction
                                    ).toLocaleString('en-US')} ${t('units.trips')}`}</TooltipText>
                                </>
                            )}
                        </TooltipContent>
                    </FeatureTooltip>
                )}
                {showCentroidEditedTooltip && (
                    <>
                        <FeatureTooltipCentroidModified
                            longitude={centroidEditedTooltipPosition.longitude}
                            latitude={centroidEditedTooltipPosition.latitude}
                            anchor={centroidEditedTooltipData?.anchor}
                            offset={5}
                            maxWidth='none'
                            closeButton={false}
                            closeOnClick={true}
                            onClose={() => {}}>
                            <TooltipFoundFeature>
                                <HeaderText variant='h1'>{`${t('mapbox.modified')}: ${
                                    centroidEditedTooltipData?.eid
                                }`}</HeaderText>
                            </TooltipFoundFeature>
                        </FeatureTooltipCentroidModified>
                        <CentroidModifiedInfo
                            centroidEditedTooltipData={centroidEditedTooltipData}
                            viewMode={viewMode}
                            optionLandUseView={optionLandUseView}
                        />
                    </>
                )}
                {_openChart && (
                    <StationDialog
                        epoch={_epoch}
                        onClose={handleChartClose}
                        valueColor={_stationFeatureIdSelected?.valueColor}
                        reliabilityColor={_stationFeatureIdSelected?.reliabilityColor}
                        stationName={_stationFeatureIdSelected?.feature_id}
                        mapId={mapId}
                        height={viewport.height}
                        width={viewport.width}
                        viewMode={viewMode}
                    />
                )}
                {openCentroidInfoModal && (
                    <CentroidInfoModal
                        centroidData={centroidClickSelectedData}
                        onClose={handleCentroidModalInfoClose}
                        edit={editCentroid}
                        optionLandUseView={optionLandUseView}
                    />
                )}
                {openSubPathInfoModal && (
                    <TrafficActionsRenderer
                        subPathNodeInfoModal={subPathNodeInfoModal}
                        onCloseNewActionModal={onCloseNewActionModal}
                        highlightDiversionRoutes={highlightDiversionRoutes}
                        clearDiversionRoutes={clearDiversionRoutes}
                        checkedDiversionRoutes={checkedDiversionRoutes}
                    />
                )}
                {viewMode.variable === 'travel_time_pt' && (
                    <PtLinesModal
                        mapId={mapId}
                        height={viewport.height}
                        highlightPtLine={highlightPtLine}
                        clearPtLine={clearPtLine}
                        checkedPtLine={checkedPtLine}
                    />
                )}
                {showLegend && (
                    <Legend
                        showLegend={true}
                        isLoading={false}
                        fetchingData={false}
                        initiallyOpen={_views_layout === 1}
                        viewModes={isBaseline ? allViewModesBaseline : allViewModes}
                        selectedViewMode={viewMode}
                        containerName={containerName}
                        changeViewMode={handleChangeViewMode}
                    />
                )}
                <LayerControlsContainer>
                    <Search handleSearch={handleSearch} handleListClick={handleListSearchClick} mapId={mapId} />
                    {actionLayers.length > 0 && (
                        <CustomSpeedDial
                            actions={actionsSpeedDial.filter(({ key }) => {
                                return actionLayers.includes(key)
                            })}
                            type='actions'
                        />
                    )}
                    {incidentLayers.length > 0 && (
                        <CustomSpeedDial
                            actions={incidentsSpeedDial.filter(({ key }) => {
                                return incidentLayers.includes(key)
                            })}
                            type='incidents'
                        />
                    )}
                    {networkLayers.length > 0 && viewMode.variable !== 'travel_time_pt' && (
                        <CustomSpeedDial
                            actions={networkSpeedDial.filter(({ key }) => {
                                return networkLayers.includes(key)
                            })}
                            type='network'
                        />
                    )}
                    <Zoom zoomIn={handleZoomIn} zoomOut={handleZoomOut} />
                </LayerControlsContainer>

                <Snackbar
                    open={feedbackOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={2000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={feedbackSeverity} sx={{ width: '100%' }}>
                        {feedbackMessage}
                    </Alert>
                </Snackbar>
            </Map>
        </MapboxWrapper>
    )
}

export default MapboxComponent
